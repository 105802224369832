import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Team } from 'netsocial-lib/src/app/interfaces/team';
import { LoadTeams, UnselectItem, SelectItem } from './team-schedule.page.actions';

export interface TeamsStateModel {
  list: Team[];
  selected: (string | number)[];
}
@State<TeamsStateModel>({
  name: 'teams',
  defaults: { list: [], selected: [] },
})
@Injectable()
export class TeamState {
  @Action(LoadTeams)
  loadTeams({ patchState }: StateContext<TeamsStateModel>, { payload }: LoadTeams) {
    patchState({
      list: payload,
    });
  }

  @Action(SelectItem)
  selectItem({ getState, patchState }: StateContext<TeamsStateModel>, { payload }: SelectItem) {
    const { selected } = getState();
    patchState({
      selected: [...selected, ...payload],
    });
  }

  @Action(UnselectItem)
  unselectItem({ patchState, getState }: StateContext<TeamsStateModel>, { payload }: UnselectItem) {
    const { selected } = getState();
    patchState({
      selected: selected.filter((item) => !payload.includes(item)),
    });
  }
}
