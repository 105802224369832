<ion-header *ngIf="showHeader" class="ion-no-border">
  <ion-toolbar>
    <ion-row class="ion-align-items-center ion-justify-content-between ion-padding-horizontal">
      <ion-row class="md-title-set ion-align-items-center">
        <ion-icon name="notifications-outline" class="ion-margin-end"> </ion-icon>
        <h4>{{ 'notificationsText' | translate }}</h4>
      </ion-row>
      <ion-button
        fill="clear"
        shape="round"
        class="button-icon ion-no-margin"
        color="medium"
        (click)="modal.dismiss()"
      >
        <ion-icon slot="icon-only" name="close"> </ion-icon>
      </ion-button>
    </ion-row>
  </ion-toolbar>
</ion-header>
<ion-list *mobxAutorun>
  <ion-item
    [class.unread]="notification.readStatus === '0'"
    [detail]="false"
    (click)="!!notification.route ? navigateNotification(notification) : undefined"
    [button]="!!notification.route"
    *ngFor="let notification of visibleNotifications"
  >
    <ion-col class="ion-no-padding">
      <div class="ion-align-items-center ly-row notification-content-container">
        <div class="ly-col notification-content">
          <h5 class="ion-no-margin">{{ notification.title }}</h5>
          <h6
            class="ion-no-margin"
            [innerHTML]="linksAndTextByNotification[notification.id].body"
          ></h6>
          <p class="ion-no-margin">{{ notification.fromNow }}</p>
        </div>
        <ion-button
          fill="solid"
          shape="round"
          class="button-icon delete-notification"
          color="dark"
          (click)="delete(notification, $event)"
        >
          <ion-icon slot="icon-only" name="close" size="small"> </ion-icon>
        </ion-button>
      </div>
      <div class="ly-row ion-align-items-center" *ngIf="notification.actions.length">
        <ion-button
          fill="clear"
          class="notification-button"
          (click)="performAction(action, notification, $event)"
          [promiseBtn]="actionPromise"
          *ngFor="let action of notification.actions"
        >
          {{ 'components.notificationList.actions.' + action.label | translate }}
        </ion-button>
      </div>
    </ion-col>
  </ion-item>
  <ion-row
    *ngIf="
      currentRenderLimit < userService.notifications?.length! && userService.notifications?.length
    "
    class="ion-justify-content-center"
  >
    <ion-button
      color="medium"
      size="small"
      (click)="currentRenderLimit = currentRenderLimit + renderLimit"
    >
      {{ 'viewMoreText' | translate }}
    </ion-button>
  </ion-row>
  <div
    class="md-icon-message md-fill"
    *ngIf="!userService.notifications || !userService.notifications.length"
  >
    <div class="notification-bell">
      <ion-icon size="large" name="notifications-outline"> </ion-icon>
    </div>
    <ion-text class="ion-text-center">
      <h4>{{ 'components.notificationList.messages.caughtUp' | translate }}</h4>
      <h6>
        {{ 'components.notificationList.messages.caughtUpDescription' | translate }}
      </h6>
    </ion-text>
  </div>
</ion-list>
<ion-row
  *ngIf="userService.notifications && userService.notifications.length"
  class="ion-justify-content-center clear-all-container"
>
  <ion-button fill="clear" color="danger" size="small" (click)="clearAll()">
    {{ 'clearAllText' | translate }}
  </ion-button>
</ion-row>
