import { Component, Input, ViewChild, OnDestroy } from '@angular/core';
import { Router, Route, NavigationEnd } from '@angular/router';
import {
  IonMenu,
  AlertController,
  ModalController,
  NavController,
  PopoverController,
  IonButton,
  Platform,
} from '@ionic/angular';
import * as Sentry from '@sentry/angular';
import { Subscription } from 'rxjs';
import { observable, computed } from 'mobx-angular';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'netsocial-posting/src/app/services/account/account.service';
import { UserService } from 'netsocial-posting/src/app/services/user/user.service';
import { ErrorService } from 'netsocial-posting/src/app/services/error/error.service';
import { NotificationService } from 'netsocial-lib/src/app/services/notification/notification.service';
import { MenuNotification } from 'netsocial-lib/src/app/interfaces/menu-notification';
import {
  ApiRequestService,
  NetSocialAccountFields,
} from 'netsocial-lib/src/app/services/api-request/api-request.service';
import { Client } from 'netsocial-lib/src/app/interfaces/client';
import { buildHash } from 'netsocial-lib/src/app/utils/array-utils';
import { hasSubString } from 'netsocial-lib/src/app/utils/string-utils';
import { PermissionInfo } from 'netsocial-lib/src/app/interfaces/permission-info';
import { BuildVersionsComponent } from '../build-version/build-version.component';
import { MainHeaderService } from '../main-header/main-header.service';

export interface RouteDef {
  id: string;
  title: string;
  mobileTitle: string;
  notificationName: string;
  icon: string;
  disabled: boolean;
  iconSrc: string;
  path: string;
  relativePath: string;
  selected: boolean;
  routes: ChildRouteDef[];
  hide: boolean;
  canActivate: boolean;
  pageName?: string;
  showComponent?: any;
  accountTypes?: string[];
  approvalsRequired?: string[];
  simplePage?: boolean;
  agencyOnly?: boolean;
  agencyOrFranchiseOnly?: boolean;
  hideEditor?: boolean;
}

export interface ChildRouteDef {
  id: string;
  title: string;
  mobileTitle: string;
  notificationName: string;
  icon: string;
  disabled: boolean;
  iconSrc: string;
  path: string;
  selected: boolean;
  hide: boolean;
  canActivate: boolean;
  pageName?: string;
  component?: any;
  componentProps?: Record<string, unknown>;
  cssClass?: string;
  showComponent?: any;
  accountTypes?: string[];
  approvalsRequired?: string[];
  agencyOnly?: boolean;
}

@Component({
  selector: 'main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent implements OnDestroy {
  @ViewChild(IonMenu, { static: false }) menu: IonMenu;
  @ViewChild('gettingStartedButton', { static: false })
  gettingStartedButton: IonButton;

  collapsed: boolean;
  _route: Route;
  showComponent: boolean | null = null;
  menuNotification: MenuNotification;
  planId: string;
  client: Client;

  private readonly NOTIFICATION_CHECK_INTERVAL = 600000;

  @Input() home: string;
  @observable currentUrl: string;
  @observable routes?: RouteDef[];

  @computed
  get isQueue(): boolean {
    return hasSubString(this.currentUrl, 'queue');
  }

  @computed
  get currentRouteData(): RouteDef | undefined {
    return this.routes?.find((route) => route.selected);
  }

  @computed
  get currentRouteHasChildren(): boolean {
    if (!this.contextOwner && this.currentRouteData?.simplePage) {
      return false;
    }

    return !!this.currentRouteData?.routes?.length;
  }

  private contextOwnerSubscription: Subscription;
  @observable contextOwner?: NetSocialAccountFields;

  @computed
  get isDesktop(): boolean {
    return this.platform.is('desktop');
  }

  constructor(
    public readonly accountService: AccountService,
    public readonly navCtrl: NavController,
    public readonly userService: UserService,
    private readonly router: Router,
    private readonly alertController: AlertController,
    private readonly translate: TranslateService,
    private readonly mainHeaderService: MainHeaderService,
    private readonly notificationService: NotificationService,
    private readonly modal: ModalController,
    private readonly apiRequest: ApiRequestService,
    private readonly popover: PopoverController,
    private readonly errorService: ErrorService,
    private readonly platform: Platform
  ) {
    this.mainHeaderService.getIonCloseComponent().subscribe(() => {
      this.mutShowComponent();
    });

    this.contextOwnerSubscription = this.apiRequest.getContextOwner().subscribe((value) => {
      this.contextOwner = value;
      this.resolveContextOwner();
      this.getMenuNotifications();
    });

    setInterval(async () => {
      const isLoggedIn = await this.userService.isLoggedIn();
      if (!isLoggedIn) {
        return this.userService.logout();
      }
      this.getMenuNotifications();
    }, this.NOTIFICATION_CHECK_INTERVAL);

    this.collapsed = false;

    if (this.userService.currentUser?.permissionInfo?.planId) {
      this.planId = this.userService.currentUser.permissionInfo.planId;
    }

    this.router.events.subscribe((event: NavigationEnd) => {
      if (
        event.url &&
        (hasSubString(event.toString(), 'NavigationEnd') ||
          hasSubString(event.toString(), 'NavigationCancel'))
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _route: any = this.router.config.find((routeConfig) => {
          return routeConfig.data && routeConfig.data.hasNavigation;
        });

        this._route = _route;
        this.home = `/${this._route.path}`;

        this.routes = _route._loadedRoutes[0].children.map(
          (route: { path: string; _loadedRoutes: any; data: any }, index: number) => {
            const path = `${this.home}${route.path ? '/' + route.path : ''}`;
            const selected =
              path === this.home ? hasSubString(path, event.url) : hasSubString(event.url, path);
            const relativePath = path === this.home ? '/' : route.path;

            const showComponent = null;

            const hasChildren = route._loadedRoutes && route._loadedRoutes[0].children;

            let routes = hasChildren
              ? route._loadedRoutes[0].children.map((childRoute: any, childIndex: number) => {
                  return this.parseChildRoute(event, path, childRoute, childIndex);
                })
              : [];

            // if (!showComponent && route.data.showComponent) {
            //   showComponent = route.data.showComponent;
            // }

            const canActivate = this.resolveCanActivate(route);

            let hide = route.data.hide;

            if (route.data.componentPages) {
              routes = [
                ...routes,
                ...route.data.componentPages.map((compPage: any, compPageIndex: number) =>
                  this.parseChildRoute(event, path, compPage, compPageIndex)
                ),
              ];
            }

            if (this.userService.currentUser?.roleLabel === 'Editor' && route.data.hideEditor) {
              hide = true;
            }

            if (
              route.data.agencyOnly &&
              this.userService.currentUser?.clientInfo?.isAgency !== undefined &&
              !Number(this.userService.currentUser?.clientInfo?.isAgency)
            ) {
              hide = true;
            }

            if (
              route.data.agencyOrFranchiseOnly &&
              this.userService.currentUser?.clientInfo?.isAgency !== undefined &&
              this.userService.currentUser?.clientInfo?.isMasterFranchiseAccount !== undefined &&
              !Number(this.userService.currentUser?.clientInfo?.isAgency) &&
              !Number(this.userService.currentUser?.clientInfo?.isMasterFranchiseAccount)
            ) {
              hide = true;
            }

            const cleanRoute: RouteDef = {
              id: `${path} ${index}`,
              title: route.data.title,
              mobileTitle: route.data.mobileTitle,
              notificationName: route.data.notificationName,
              icon: route.data.icon,
              disabled: route.data.disabled,
              iconSrc: route.data.iconSrc,
              path,
              relativePath,
              selected,
              routes,
              hide,
              canActivate,
              simplePage: route.data.simplePage,
              pageName: route.path.split('/')[route.path.split('/').length - 1],
              accountTypes: route.data.canActivate?.accountTypes || [],
              approvalsRequired: route.data.canActivate?.approvalsRequired || [],
            };

            if ((selected && route.data.showComponent) || showComponent) {
              this.showComponent = route.data.showComponent || showComponent;
              cleanRoute.showComponent = route.data.showComponent || showComponent;
            }

            return cleanRoute;
          }
        );

        this.currentUrl = event.url;
        this.resolveContextOwner();
        this.resolveApprovalRequirements();
      }
    });
  }

  async openTourStatus(event: Event): Promise<void> {
    this.mainHeaderService.openTourStatus(event, this.popover);
  }

  ngOnDestroy(): void {
    this.contextOwnerSubscription?.unsubscribe();
  }

  mutShowComponent(route?: RouteDef | ChildRouteDef): void {
    if (this.showComponent) {
      this.showComponent = null;
    } else if (route && route.showComponent) {
      this.showComponent = route.showComponent;
    } else {
      this.showComponent = null;
    }
  }

  private resolveContextOwner() {
    const accountType = this.contextOwner
      ? this.userService.currentUser?.permissionInfo.planId === '6' &&
        this.userService.currentUser.clientId === this.contextOwner.ownerId
        ? 'agency'
        : this.contextOwner.accountType
      : 'myself';
    Sentry.addBreadcrumb({
      category: 'Changed Account Type',
      message: 'Account Type Switch:' + accountType,
      level: 'info',
    });
    this.routes?.forEach((parent) => {
      if (parent.accountTypes?.length) {
        const accountTypeHash = buildHash(parent.accountTypes);
        parent.canActivate = !!accountTypeHash[accountType];
        if (!parent.canActivate && parent.selected) {
          this.navigateTo(this.userService.homePage);
        }
      }
      parent.routes.forEach((child) => {
        if (child.accountTypes?.length) {
          const childAccountTypeHash = buildHash(child.accountTypes || []);
          child.canActivate = !!childAccountTypeHash[accountType];
          if (!child.canActivate && child.selected) {
            this.navigateTo(this.userService.homePage);
          }
        }
      });
    });
  }

  private resolveApprovalRequirements() {
    this.routes?.forEach((parent) => {
      if (typeof parent.approvalsRequired !== 'undefined' && parent.approvalsRequired.length) {
        const approvalsRequiredHash = buildHash(parent.approvalsRequired);
        if (
          parent.canActivate &&
          typeof this.userService.currentUser?.clientInfo.approvalsRequired !== 'undefined'
        ) {
          parent.canActivate =
            !!approvalsRequiredHash[
              this.userService.currentUser.clientInfo.approvalsRequired.toString()
            ];
        }
        if (!parent.canActivate && parent.selected) {
          this.navigateTo(this.userService.homePage);
        }
      }

      parent.routes.forEach((child) => {
        if (child.approvalsRequired?.length) {
          const childApprovalsRequiredHash = buildHash(child.approvalsRequired);
          if (
            child.canActivate &&
            typeof this.userService.currentUser?.clientInfo.approvalsRequired !== 'undefined'
          ) {
            child.canActivate =
              !!childApprovalsRequiredHash[
                this.userService.currentUser.clientInfo.approvalsRequired.toString()
              ];
          }
          if (!child.canActivate && child.selected) {
            this.navigateTo(this.userService.homePage);
          }
        }
      });
    });
  }

  private resolveCanActivate(route: any) {
    const routeData = route.data ? route.data : {};
    const reqPermissions: string[] =
      routeData.canActivate && routeData.canActivate.permissions
        ? routeData.canActivate.permissions
        : [];
    const reqRoles: string[] =
      routeData.canActivate && routeData.canActivate.roles ? routeData.canActivate.roles : [];
    const permissions = this.userService.currentUser?.permissionInfo.permissions;

    let canActivate = reqPermissions.length || reqRoles.length ? false : true;

    canActivate = reqPermissions.reduce(
      (_canActivate, permission: keyof PermissionInfo['permissions']) => {
        if (permissions && permissions[permission] === 'enabled') {
          _canActivate = true;
        }
        return _canActivate;
      },
      canActivate
    );

    canActivate = reqRoles.reduce((_canActivate, role) => {
      if (this.userService.currentUser?.roleLabel === role) {
        _canActivate = true;
      }
      return _canActivate;
    }, canActivate);

    if (this.userService.currentUser?.roleLabel === 'Editor' && routeData.hideEditor) {
      canActivate = false;
    }

    if (
      routeData.agencyOnly &&
      this.userService.currentUser?.clientInfo?.isAgency !== undefined &&
      !Number(this.userService.currentUser?.clientInfo?.isAgency)
    ) {
      canActivate = false;
    }

    if (
      routeData.agencyOrFranchiseOnly &&
      this.userService.currentUser?.clientInfo?.isAgency !== undefined &&
      this.userService.currentUser?.clientInfo?.isMasterFranchiseAccount !== undefined &&
      !Number(this.userService.currentUser?.clientInfo?.isAgency) &&
      !Number(this.userService.currentUser?.clientInfo?.isMasterFranchiseAccount)
    ) {
      canActivate = true;
    }

    return canActivate;
  }

  private parseChildRoute(event: NavigationEnd, path: string, route: any, index: number) {
    let eventPath = event.urlAfterRedirects;

    if (hasSubString(eventPath, '?')) {
      eventPath = eventPath.split('?')[0];
    }

    const childPath =
      route.path !== undefined ? `${path}${route.path ? '/' + route.path : ''}` : undefined;
    let selectedChild;

    if (hasSubString(childPath || '', ':id')) {
      const childPathUrlParts = childPath?.split('/');

      selectedChild = eventPath.split('/').reduce((isSelected, urlPart, partIndex: number) => {
        if (!isNaN(parseInt(urlPart)) && isSelected && childPathUrlParts) {
          isSelected =
            childPathUrlParts[partIndex] !== undefined && childPathUrlParts[partIndex] === ':id'
              ? true
              : false;
        } else if (isSelected && childPathUrlParts) {
          isSelected =
            childPathUrlParts[partIndex] !== undefined && childPathUrlParts[partIndex] === urlPart
              ? true
              : false;
        }

        return isSelected;
      }, true);
    } else {
      selectedChild = childPath === eventPath;
    }

    const childHide = route.data?.hide;
    const childCanActivate = this.resolveCanActivate(route);

    const cleanChildRoute: ChildRouteDef = {
      id: `${path} ${index}`,
      mobileTitle: route.data?.mobileTitle,
      title: route.data?.title,
      notificationName: route.data?.notificationName,
      icon: route.data?.icon,
      disabled: route.data?.disabled,
      iconSrc: route.data?.iconSrc,
      path: childPath || '',
      selected: selectedChild,
      hide: childHide,
      canActivate: childCanActivate,
      component: route.data?.component,
      componentProps: route.data?.componentProps,
      cssClass: route.data?.cssClass,
      pageName: route.path ? route.path.split('/')[route.path.split('/').length - 1] : undefined,
      accountTypes: route.data.canActivate?.accountTypes || [],
      approvalsRequired: route.data.canActivate?.approvalsRequired || [],
    };

    if (selectedChild && route.data?.showComponent) {
      this.showComponent = route.data.showComponent;
      cleanChildRoute.showComponent = route.data.showComponent;
    }

    // if (selectedChild && route.data.showComponent && childHide) {
    //   showComponent = route.data.showComponent;
    // }

    return cleanChildRoute;
  }

  async showBuildVersions(): Promise<void> {
    (
      await this.modal.create({
        component: BuildVersionsComponent,
        componentProps: { currentUser: this.userService.currentUser },
        cssClass: 'build-version-modal',
      })
    ).present();
  }

  async getMenuNotifications(): Promise<void> {
    this.errorService.resolve(this.notificationService.getMenuNotifications(), {
      errorMessage: 'There was an error tying to check if you had any new menu notifications',
      successCallback: (menuNotification) => {
        this.menuNotification = menuNotification;
      },
    });
  }

  async openComponent(route: ChildRouteDef): Promise<void> {
    const modal = await this.modal.create({
      component: route.component,
      componentProps: route.componentProps,
      cssClass: route.cssClass,
    });

    return modal.present();
  }

  navigateTo(path: string): Promise<boolean> {
    this.menu.close();
    return this.navCtrl.navigateRoot([path]);
  }

  collapse(): void {
    const body = document.querySelector('body');
    if (body && body.clientWidth > 991) {
      this.collapsed = !this.collapsed;
    } else {
      this.menu.close();
    }
  }

  trackElement(_index: number, element: RouteDef | ChildRouteDef): string | null {
    return element ? element.id : null;
  }
}
