<form [formGroup]="inputConfig.formGroup">
  <ion-input
    *ngIf="inputConfig.type === 'text'"
    formControlName="{{ inputConfig.formControlName }}"
  >
  </ion-input>

  <div class="toggle" *ngIf="inputConfig.type === 'toggle'">
    <ion-checkbox
      formControlName="{{ inputConfig.formControlName }}"
      *ngIf="!inputConfig.icon"
      [mode]="inputConfig.mode"
    >
    </ion-checkbox>
    <span (click)="toggleValue()" *ngIf="!inputConfig.icon && inputConfig.label">
      {{ inputConfig.label }}
    </span>

    <ion-button (click)="toggleValue()" [fill]="inputConfig.buttonFill" *ngIf="inputConfig.icon">
      <ion-icon
        [name]="
          inputConfig.formGroup.get(inputConfig.formControlName)?.value
            ? inputConfig.icon?.true
            : inputConfig.icon?.false
        "
      >
      </ion-icon>
    </ion-button>
  </div>
</form>
