import { Component, Renderer2 } from '@angular/core';
import { observable } from 'mobx-angular';
import { ConfiguredTours, UserService } from 'netsocial-posting/src/app/services/user/user.service';
import { StorageService } from 'netsocial-lib/src/app/services/storage/storage.service';

@Component({
  selector: 'tour-status',
  templateUrl: './tour-status.component.html',
  styleUrls: ['./tour-status.component.scss'],
})
export class TourStatusComponent {
  public popover: HTMLIonPopoverElement;

  @observable public showSuccessAnimation: boolean;

  constructor(
    public userService: UserService,
    private storage: StorageService,
    private renderer: Renderer2
  ) {}

  async cancelTour(): Promise<void> {
    await this.popover.dismiss();
    this.storage.set('completedTours', this.userService.currentTours);
    this.userService.completedTours = this.userService.currentTours;
  }

  async startTour(tour: ConfiguredTours): Promise<void> {
    const $popover = document.querySelector<HTMLIonPopoverElement>('.tour-status-popover');

    this.userService.startTour({
      tour,
      ownerContextToCache: this.userService.currentOwnerContextInput,
      netSocialAccounts: this.userService.paginatedNetsocialAccounts
        ? this.userService.paginatedNetsocialAccounts.items
        : [],
      container: $popover,
      changeContextOwner: (owner) => {
        return this.userService.mutContextOwner(owner);
      },
      successCallBack: () => {
        this.showSuccessAnimation = true;
      },
      successAnimationFinishedCallBack: () => {
        this.showSuccessAnimation = false;
      },
      renderer: this.renderer,
    });
  }
}
