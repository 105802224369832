import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'netsocial-posting/src/app/services/user/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ns-header',
  templateUrl: './ns-header.component.html',
  styleUrls: ['./ns-header.component.scss'],
})
export class NsHeaderComponent {
  @Input() public enableCenterLayout: boolean;
  @Input() public logoLink = '/auth/account-create';
  @Input() public enableLogoutButton: boolean;

  @Output() public ionLogout = new EventEmitter();

  constructor(public userService: UserService, public router: Router) {}

  async logout() {
    await this.userService.logout();
    this.ionLogout.emit();
  }
}
