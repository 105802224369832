import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { observable, computed } from 'mobx-angular';
import { DateRangeInput } from '@fullcalendar/angular';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ViewFormat } from '../../queue-header/queue-header.component';
import { OwlDateTimeInputDirective } from '@danielmoncada/angular-datetime-picker';
import { add, format, parse, set, sub } from 'date-fns';
import { endOf, startOf } from 'netsocial-lib/src/app/utils/date-utils';
import { SelectMode } from '@danielmoncada/angular-datetime-picker/lib/date-time/date-time.class';

export type RangeInterval = 'month' | 'week' | 'day';

interface DateInterval {
  start: Date;
  end: Date;
}

@Component({
  selector: 'ns-date-range-picker',
  templateUrl: './ns-date-range-picker.component.html',
  styleUrls: ['./ns-date-range-picker.component.scss'],
})
export class NsDateRangePickerComponent implements OnChanges {
  @observable @Input() interval: RangeInterval = 'month';
  @Output() ionChange = new EventEmitter<DateRangeInput>();

  @ViewChild(OwlDateTimeInputDirective)
  dateTimeInput: OwlDateTimeInputDirective<Date>;

  @observable @Input() value: Date;
  @observable internalValue: Date;

  static getRangeIntervalForViewFormat(viewFormat: ViewFormat): RangeInterval {
    switch (viewFormat) {
      case 'months':
        return 'month';
      case 'weekdays':
        return 'week';
      case 'hours':
        return 'day';
    }
  }

  constructor(private sanitizer: DomSanitizer) {
    if (!this.value) {
      this.value = new Date();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.internalValue = this.value;
    }

    if (changes.interval && !changes.interval.isFirstChange()) {
      this.change();
    }
  }

  @computed
  get startView(): string {
    return this.interval === 'month' ? 'year' : 'month';
  }

  @computed
  get selectMode(): SelectMode {
    return this.interval === 'week' ? 'range' : 'single';
  }

  @computed
  get intervalDefinition(): DateInterval {
    const value = this.internalValue || this.value;
    return {
      start: startOf(value, this.interval),
      end: endOf(value, this.interval),
    };
  }

  @computed
  get intervalValue(): Date | Date[] {
    return this.interval === 'week' || this.interval === 'month'
      ? [this.intervalDefinition.start, this.intervalDefinition.end]
      : this.value;
  }
  set intervalValue(_value: Date | Date[]) {
    //
  }

  @computed
  get formattedValue(): SafeHtml {
    switch (this.interval) {
      case 'month': {
        return format(this.value, 'MMMM yyyy');
      }
      case 'week': {
        const day = 'dd';
        const month = 'MMM';
        const year = 'yyyy';
        return this.sanitizer.bypassSecurityTrustHtml(
          `${format(this.intervalDefinition.start, month)}
          ${format(this.intervalDefinition.start, day)}
          <ion-icon name="remove"></ion-icon>
          ${
            format(this.intervalDefinition.start, month) !==
            format(this.intervalDefinition.end, month)
              ? format(this.intervalDefinition.end, month)
              : ''
          }
          ${format(this.intervalDefinition.end, day)}, 
          ${format(this.intervalDefinition.start, year)}`
        );
      }
      case 'day': {
        return format(this.value, 'M/dd/yyyy');
      }
    }
  }

  openCalendar(): void {
    this.dateTimeInput.dtPicker.open();
  }

  chooseMonth(): void {
    if (this.interval === 'month') {
      const clickEvent = <MouseEvent>event;
      const year = Number(
        document.querySelector('.owl-dt-control-period-button')?.textContent?.trim()
      );
      const targetMonth = (<HTMLElement | null>clickEvent.target)?.textContent?.trim();
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      if (targetMonth) {
        const month = months.indexOf(targetMonth);
        this.internalValue = set(new Date(), { month, year });
        this.change({ newValue: set(new Date(), { month, year }) });
        this.dateTimeInput.dtPicker.close();
        this.dateTimeInput.dtPicker.isInRangeMode;
      }
    }
  }

  change(
    options: {
      action?: 'prev' | 'next';
      newValue?: Date;
    } = {}
  ): void {
    let { newValue } = options;
    const { action } = options;

    const _options: Duration = {};

    if (this.interval === 'month') {
      _options.months = 1;
    } else if (this.interval === 'week') {
      _options.weeks = 1;
    } else if (this.interval === 'day') {
      _options.days = 1;
    }

    const monthChanged = format(this.internalValue, 'MMM') !== format(new Date(), 'MMM');

    if (action === 'prev') {
      newValue = sub(this.value, _options);
    } else if (action === 'next') {
      newValue = add(this.value, _options);
    } else {
      if (this.interval === 'month') {
        newValue = monthChanged ? this.internalValue : new Date();
      } else {
        newValue = newValue || this.value;
      }
    }

    this.ionChange.emit({
      start: startOf(newValue, this.interval),
      end: endOf(newValue, this.interval),
    });
  }

  addListeners(addArrowButtonListeners = true): void {
    if (this.interval === 'week' || this.interval === 'day') {
      const days = document.querySelectorAll<HTMLTableCellElement>('.owl-dt-calendar-cell');

      if (addArrowButtonListeners) {
        this.addListenersForArrowButtons();
      }

      days.forEach((day) => {
        day.addEventListener('click', this.handleCalendarClick.bind(this));
      });
    }
  }

  private handleCalendarClick(event: MouseEvent) {
    let target = <HTMLElement>event.target;

    if (target instanceof HTMLSpanElement && target.parentElement) {
      target = target.parentElement;
    }

    const row = <HTMLTableRowElement>target.parentElement;
    const daysInRow = row.querySelectorAll<HTMLTableCellElement>('td');
    const dayToUse = this.interval === 'week' ? daysInRow[0] : target;
    const start = dayToUse.getAttribute('aria-label') ?? '';
    const _format = 'MMMM dd, yyyy';
    const newValue = parse(start, _format, new Date());

    this.change({ newValue });
    this.dateTimeInput.dtPicker.close();
  }

  private addListenersForArrowButtons(): void {
    const nextButtons = document.querySelectorAll<HTMLButtonElement>(
      '.owl-dt-control.owl-dt-control-button.owl-dt-control-arrow-button'
    );
    nextButtons.forEach((button) => {
      button.addEventListener('click', () => {
        this.addListeners(false);
      });
    });
  }
}
