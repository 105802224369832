<div class="header-wrapper">
  <div class="header-inner-wrapper">
    <ion-header class="ion-no-border" *mobxAutorun>
      <ion-toolbar
        [class.company-view]="userService.currentOwnerContext?.accountType === 'company'"
        [style.borderColor]="
          !clientLoading && userService.currentOwnerContext?.accountType === 'company'
            ? agencyAdmin
              ? 'DarkRed'
              : client?.brandColor
            : undefined
        "
        [style.background]="
          !clientLoading && userService.currentOwnerContext?.accountType === 'company'
            ? agencyAdmin
              ? 'DarkRed'
              : client?.brandColor
            : undefined
        "
      >
        <ion-row class="ion-align-items-center flex-no-wrap">
          <ion-buttons>
            <ion-menu-toggle>
              <ion-button fill="clear">
                <ion-icon name="menu" color="dark"> </ion-icon>
              </ion-button>
            </ion-menu-toggle>
          </ion-buttons>
          <ion-icon
            *ngIf="(currentRouteData.icon || currentRouteData.iconSrc) && isDesktop"
            [name]="currentRouteData.icon"
            [src]="currentRouteData.iconSrc"
            color="medium"
            size="large"
            class="ion-margin-horizontal main-icon flex-none"
            style="pointer-events: none"
          >
          </ion-icon>
          <ion-title class="ion-no-padding ion-margin-end">
            <ion-text>
              <h2 class="ion-no-margin">
                {{ title | translate }}
              </h2>
            </ion-text>
          </ion-title>

          <ng-template #counter let-step="step" let-total="total">
            <step-counter [step]="step" [total]="total" color="light"> </step-counter>
          </ng-template>

          <ion-buttons
            *ngIf="userService.currentUser?.permissionInfo?.permissions?.viewAsMenu === 'enabled'"
            class="md-fill md-context-button flex"
            joyrideStep="viewAs"
            [title]="'components.mainHeader.tour.viewAs.title' | translate"
            [text]="'components.mainHeader.tour.viewAs.body' | translate"
            [counterTemplate]="counter"
          >
            <ns-select
              #contextSelect
              color="dark"
              uniqueField="ownerId"
              labelField="name"
              label="viewingAsText"
              [customTrigger]="true"
              [enableSearch]="userService.netSocialAccountsInput?.items?.length! > 4 ? true : false"
              [items]="userService.netSocialAccountsInput!"
              [value]="userService.currentOwnerContextInput"
              [disabled]="userService.loadingNetSocialAccounts"
              (ionChange)="userService.mutContextOwner($event); reloadClientDetails($event)"
            >
              <ng-container *ngIf="isDesktop">
                <entity-avatar
                  [entity]="
                    userService.currentOwnerContext?.accountType === 'company'
                      ? selectedClient
                      : userService.currentUser?.clientInfo
                  "
                  [loading]="
                    clientLoading && userService.currentOwnerContext?.accountType === 'company'
                  "
                >
                </entity-avatar>
                <ion-button [disabled]="userService.loadingNetSocialAccounts" class="flex">
                  <ion-row
                    class="ion-align-items-center flex-no-wrap text-ellipse"
                    [innerHTML]="
                      'components.mainHeader.messages.viewingAs'
                        | translate: { context: viewingAs | translate }
                    "
                  >
                  </ion-row>
                  <ion-icon slot="end" name="caret-down-sharp" size="small" class="flex-none">
                  </ion-icon>
                </ion-button>
              </ng-container>
            </ns-select>
          </ion-buttons>
        </ion-row>
        <ion-buttons
          [class.unread]="userService.hasUnreadNotifications"
          slot="end"
          class="md-fill md-notification-button"
        >
          <ion-button (click)="showNotifications($event)" class="button-icon" color="dark">
            <ion-icon slot="icon-only" name="notifications"> </ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end" class="md-fill md-personal-button">
          <ion-button class="profile-image-button" (click)="goToProfilePage()">
            <entity-avatar [entity]="userService.currentUser"> </entity-avatar>
          </ion-button>
          <ion-button (click)="openUserMenu($event)" class="dropdown-button">
            <entity-avatar [entity]="userService.currentUser" class="ion-margin-end">
            </entity-avatar>
            <ion-row
              class="ion-align-items-center text-ellipse"
              [innerHTML]="
                'workspace.header.userGreeting'
                  | translate: { name: userService.currentUser?.fullName }
              "
            >
            </ion-row>
            <ion-icon slot="end" name="caret-down-sharp" size="small"> </ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-badge
      *ngIf="userService.currentOwnerContext?.accountType === 'company'"
      class="md-large company-view-indicator"
      [class.mobile-indicator]="!isDesktop"
      [style.background]="
        !clientLoading && userService.currentOwnerContext?.accountType === 'company'
          ? agencyAdmin
            ? 'DarkRed'
            : client?.brandColor
          : undefined
      "
    >
      <ng-container *ngIf="isDesktop">
        <span *ngIf="!clientLoading" class="ion-text-uppercase">
          {{ agencyAdmin ? ('agencyViewText' | translate) : ('companyViewText' | translate) }}
        </span>
      </ng-container>

      <ng-container *ngIf="!isDesktop">
        <span>
          {{ 'viewingAsText' | translate }}
          <b>
            {{ viewingAs | translate }}
          </b>
        </span>
      </ng-container>
    </ion-badge>
    <ion-badge
      *ngIf="
        userService.currentOwnerContext?.accountType !== 'company' &&
        !isDesktop &&
        userService.currentUser?.permissionInfo?.permissions?.viewAsMenu === 'enabled'
      "
      class="md-large myself-view-indicator"
      [class.mobile-indicator]="!isDesktop"
      [class.ion-margin-bottom]="!isDesktop"
      [style.background]="'primary'"
    >
      <ng-container *ngIf="!isDesktop">
        <span>
          {{ 'viewingAsText' | translate }}
          <b>
            {{ viewingAs | translate }}
          </b>
        </span>
      </ng-container>
    </ion-badge>

    <ion-button
      *ngIf="
        !isDesktop && userService.currentUser?.permissionInfo?.permissions?.viewAsMenu === 'enabled'
      "
      size="small"
      border-radius="50px"
      class="company-view-button"
      [color]="
        clientLoading || userService.currentOwnerContext?.accountType !== 'company'
          ? 'primary'
          : undefined
      "
      [class.myself]="userService.currentOwnerContext?.accountType !== 'company'"
      (click)="openContextMenu($event)"
      [style.--background]="
        !clientLoading && userService.currentOwnerContext?.accountType === 'company'
          ? agencyAdmin
            ? 'DarkRed'
            : client?.brandColor
          : undefined
      "
    >
      <ion-icon name="repeat"></ion-icon>
    </ion-button>
  </div>
</div>
