<ion-button
  (click)="helpAlert()"
  tooltip="{{ message }}"
  [hide-delay]="25"
  size="small"
  fill="clear"
>
  {{ label }}&nbsp;
  <ion-icon name="help-circle-outline" style="pointer-events: none"> </ion-icon>
</ion-button>
