import { Component, Input, OnDestroy } from '@angular/core';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { ISnapshotInfo } from 'cordova-plugin-ionic/dist/ngx/IonicCordova';
import { from, Subscription } from 'rxjs';
import { versions } from '../../../../environments/versions';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import { computed } from 'mobx-angular';
import { ApiRequestService } from 'netsocial-lib/src/app/services/api-request/api-request.service';
import { User } from 'netsocial-lib/src/app/interfaces/user';

export const DEV_ENVIRONMENTS = ['release', 'release2', 'bugfix'];

@Component({
  selector: 'build-version',
  templateUrl: './build-version.component.html',
  styleUrls: ['./build-version.component.scss'],
})
export class BuildVersionsComponent implements OnDestroy {
  @Input() currentUser: User;

  @computed get userId(): string | number {
    return this.currentUser?.id;
  }
  @computed get clientId(): string | number {
    return this.currentUser?.clientId;
  }
  /**
   * version info written at run time
   */
  versions: Record<string, unknown> = versions;

  /**
   * version info from appflow deploy library
   */
  versionInfo?: ISnapshotInfo;

  /**
   * whether the current deployment environment is a dev environment
   */
  isDevMode: boolean;

  /**
   * IOS or Android version
   */
  appVersion: string;

  private buildInfoSubscription?: Subscription;

  constructor(private readonly deploy: Deploy, private readonly platform: Platform) {
    if (ApiRequestService.envTarget) {
      !this.isDevMode && this.setAppInfo();
      this.buildInfoSubscription = from(this.deploy.getCurrentVersion()).subscribe(
        (versionInfo) => (this.versionInfo = versionInfo)
      );
    }
  }

  private async setAppInfo(): Promise<void> {
    if (this.platform.is('ios') || this.platform.is('android')) {
      const info = await App.getInfo();
      this.appVersion = info.version;
    }
  }

  ngOnDestroy(): void {
    this.buildInfoSubscription?.unsubscribe();
  }
}
