import { Injectable } from '@angular/core';
import { ClientService } from 'netsocial-lib/src/app/services/client/client.service';
import { SetClientParams } from '../agency/agency.service';

import { User } from 'netsocial-lib/src/app/interfaces/user';
import { UserService } from 'netsocial-posting/src/app/services/user/user.service';
import { Plan } from 'netsocial-lib/src/app/interfaces/plan';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../role/role.service';
import { Client } from 'netsocial-lib/src/app/interfaces/client';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  public selectedPlan?: Plan;
  public newClient?: Client;
  public newUser?: User;
  public emailsToInvite: { email: string }[];
  public creatingAccounts: boolean;
  public registrationError?: string;

  constructor(
    private clientService: ClientService,
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private roleService: RoleService
  ) {
    const user: Partial<User> = {
      firstName: '',
      password: '',
      email: '',
      create: 1,
      sendNotification: 1,
    };

    this.newUser = new User(user);

    this.creatingAccounts = false;
  }

  nextStep() {
    if (this.router.url === '/auth/account-create') {
      return this.router.navigate(['auth/account-create/account']);
    }

    if (
      this.router.url === '/auth/account-create/account' &&
      this.selectedPlan?.name !== 'Free' &&
      this.selectedPlan?.name !== 'Pro'
    ) {
      return this.router.navigate(['auth/account-create/client']);
    }

    // if (parseInt(this.selectedPlan.limits.teamMembers) > 1) {
    //   return this.router.navigate(['auth/account-create/team-members']);
    // }
  }

  async beginRegistration() {
    try {
      this.router.navigate(['auth/account-create/finish']);
      this.creatingAccounts = true;
      const roles = await this.roleService.getRoles();

      if (this.selectedPlan?.name !== 'Free' && this.selectedPlan?.name !== 'Pro') {
        const params: SetClientParams = {};
        const role = roles.find((r) => r.name === 'Client::Owner');

        params.planId = this.selectedPlan?.id;
        params.name = this.newClient?.name;
        params.fields = this.clientService.fields;
        params.create = 1;

        if (this.selectedPlan?.name === 'Agency') {
          params.isAgency = 1;
        }

        const newClient = await this.clientService.setClient(params);
        this.newClient = newClient;

        this.newUser!.clientId = newClient.id;
        this.newUser!.roleId = role?.id;
      } else {
        const role = roles.find((r) => r.name === 'Individual');
        this.newUser!.roleId = role?.id;
        this.newUser!.clientId = 2;
        this.newUser!.planId = this.selectedPlan.id;
      }

      const actualUser = await this.userService.setTeamMember(this.newUser);
      await this.userService.login({
        email: this.newUser!.email,
        password: this.newUser!.password,
        clientId: this.newUser!.clientId,
      });
      await this.userService.loadUser(actualUser.id);

      this.selectedPlan = undefined;

      const user: Partial<User> = {
        firstName: '',
        password: '',
        email: '',
        create: 1,
      };

      this.newUser = new User(user);

      this.newClient = undefined;
      this.emailsToInvite = [];
      this.registrationError = undefined;
    } catch (error) {
      const message = await firstValueFrom(
        this.translate.get('services.registration.messages.genericError')
      );
      this.registrationError = error.results ? error.results.xstatus : message;
    } finally {
      this.creatingAccounts = false;
    }
  }
}
