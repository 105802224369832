import { Injectable } from '@angular/core';
import { ApiRequestService } from 'netsocial-lib/src/app/services/api-request/api-request.service';
import { Client } from 'netsocial-lib/src/app/interfaces/client';
import { StateNumInt } from 'netsocial-lib/src/app/interfaces/types';
import { Plan } from 'netsocial-lib/src/app/interfaces/plan';
import { User } from 'netsocial-lib/src/app/interfaces/user';
import { AlertController } from '@ionic/angular';
import { ErrorService } from 'netsocial-posting/src/app/services/error/error.service';
import { ClientField, ClientService } from 'netsocial-lib/src/app/services/client/client.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

export type ClientType = 'agency' | 'franchise';

export interface GetClientsParams {
  fields?: ClientField[];
  clientType?: ClientType;
  name?: string;
  ownerId?: string;
}

export interface SetClientParams {
  id?: Client['id'];
  create?: 1;
  active?: StateNumInt;
  isAgency?: StateNumInt;
  isMasterFranchiseAccount?: StateNumInt;
  franchiseParentClientId?: Client['id'];
  planId?: Plan['id'];
  address?: Client['address'];
  address2?: Client['address2'];
  city?: Client['city'];
  country?: Client['country'];
  defaultContactId?: User['id'];
  description?: Client['description'];
  internalName?: string;
  name?: Client['name'];
  notes?: string;
  state?: Client['state'];
  timezone?: Client['timezone'];
  url?: Client['url'];
  zip?: Client['zip'];
  fields?: GetClientsParams['fields'];
}

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  public readonly clientTypes: ClientType[] = ['agency', 'franchise'];

  constructor(
    private apiRequest: ApiRequestService,
    private alert: AlertController,
    private errorService: ErrorService,
    private translate: TranslateService,
    private clientService: ClientService
  ) {}

  async getClients(body: GetClientsParams = {}) {
    const response = await this.apiRequest.request('clients/get', { body });

    return (<[]>response.results.data)
      .map((client) => new Client(client))
      .filter((client) => {
        return (
          client.franchiseParentClientId === body.ownerId || client.agencyParentId === body.ownerId
        );
      });
  }

  async getClientById(id: Client['id'], body: GetClientsParams = {}) {
    const response = await this.apiRequest.request(`client/get/${id}`, {
      body,
    });
    return new Client(response.results.data);
  }

  async toggleActiveAction(client: Client, callback?: () => void) {
    const action = client.activeNum ? 'disable' : 'enable';
    const params = { action };
    const header = await firstValueFrom(this.translate.get(`${action}Text`));
    const cancelText = await firstValueFrom(this.translate.get('cancelText'));
    const message = await firstValueFrom(
      this.translate.get('services.agency.messages.toggleActive', params)
    );

    const alert = await this.alert.create({
      header,
      message,
      buttons: [
        {
          text: cancelText,
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: header,
          handler: async () => {
            this.errorService.resolve(
              this.clientService.setClient({
                id: client.id,
                active: client.activeNum ? 0 : 1,
              }),
              {
                errorMessage: 'services.agency.errors.toggleActive',
                errorParams: params,
                successCallback: () => {
                  client.active = client.activeNum ? '0' : '1';
                  if (callback) {
                    return callback();
                  }
                },
              }
            );
          },
        },
      ],
    });

    await alert.present();
    return await alert.onDidDismiss();
  }
}
