<ion-app
  [class.onboarding]="
    userService.currentUser &&
    !userService.currentUser.isOnboardedNum &&
    userService.currentUser.emailValidNum
  "
>
  <ion-modal
    class="update-modal"
    [isOpen]="showUpdateAvailable"
    [backdropDismiss]="false"
    [keyboardClose]="false"
  >
    <ng-template>
      <update-available *ngIf="showUpdateAvailable"> </update-available>
    </ng-template>
  </ion-modal>

  <ion-router-outlet></ion-router-outlet>
  <app-loader
    *ngIf="
      userService.currentUser &&
      !userService.currentUser.isOnboardedNum &&
      userService.currentUser.emailValidNum
    "
  >
  </app-loader>
</ion-app>
