import { Component, OnInit, Input } from '@angular/core';
import { observable, computed } from 'mobx-angular';
import { User } from 'netsocial-lib/src/app/interfaces/user';
import { UserService } from 'netsocial-posting/src/app/services/user/user.service';
import { MediaService } from 'netsocial-posting/src/app/services/media/media.service';
import { ErrorService } from 'netsocial-posting/src/app/services/error/error.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';
import { StateNumInt } from 'netsocial-lib/src/app/interfaces/types';
import FileInfo from 'netsocial-lib/src/app/interfaces/file-info';
import { ClientService } from 'netsocial-lib/src/app/services/client/client.service';
import { Client } from 'netsocial-lib/src/app/interfaces/client';
import { AgencyService } from '../../../services/agency/agency.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'avatar-logo',
  templateUrl: './avatar-logo.component.html',
  styleUrls: ['./avatar-logo.component.scss'],
})
export class AvatarLogoComponent implements OnInit {
  @Input() type: 'user' | 'company';
  @Input() client: Client;

  currentUser: User;
  uploadingAvatar = false;
  loadingUser: boolean;

  loading: boolean;
  classNames: string;

  private fileInput: HTMLInputElement;

  @observable readonly permittedFileTypes = {
    'image/gif': true,
    'image/png': true,
    'image/jpeg': true,
    'image/jpg': true,
  };

  @computed
  get fileTypeList(): string {
    return Object.keys(this.permittedFileTypes)
      .map((type) => type.split('/')[1])
      .join(', ');
  }

  constructor(
    readonly userService: UserService,
    private readonly clientService: ClientService,
    private readonly agencyService: AgencyService,
    private readonly mediaService: MediaService,
    private readonly errorService: ErrorService,
    private readonly translate: TranslateService,
    private readonly toast: ToastController
  ) {}

  ngOnInit(): void {
    this.loadUser().then((user) => {
      this.currentUser = user!;
    });
  }

  async loadUser(includePermissionInfo: StateNumInt = 0): Promise<User | undefined> {
    this.loadingUser = true;
    const user = this.userService.currentUser
      ? await this.errorService.resolve(
          this.userService.getTeamMemberById(this.userService.currentUser.id, {
            includePermissionInfo,
            includeClientInfo: 1,
          }),
          {
            errorMessage: 'workspace.settings.accountSettings.errors.getUser',
          }
        )
      : undefined;
    this.loadingUser = false;
    return user;
  }

  selectFile(): void {
    const $input = this.fileInput || document.createElement('input');
    $input.type = 'file';
    $input.multiple = true;

    $input.onchange = () => {
      Array.from($input.files ?? []).forEach(async (file: File) => {
        const filePermitted =
          this.permittedFileTypes[<keyof AvatarLogoComponent['permittedFileTypes']>file.type];
        const fileToUpload = new FileInfo(file);
        const fileValid = true;

        if (filePermitted && fileValid === true) {
          await this.uploadFile(fileToUpload);
        } else {
          const message = await firstValueFrom(
            this.translate.get(
              `components.postInput.errors.${!filePermitted ? 'fileType' : 'fileSize'}`,
              {
                // fileType: this.fileTypeList,
                fileRestriction: fileValid,
              }
            )
          );

          const toast = await this.toast.create({
            message,
            position: 'top',
            duration: 3000,
          });

          toast.present();
        }
      });
    };

    $input.click();

    this.fileInput = $input;
  }

  async uploadFile(fileInfo: FileInfo): Promise<void> {
    if (this.userService.currentUser?.isAgency) {
      fileInfo.ownerId = this.userService.currentOwnerContext?.ownerId;
      fileInfo.accountType = 'company';
    }
    try {
      this.uploadingAvatar = true;
      fileInfo.media = await this.mediaService.setAndUploadMedia(<FileInfo>fileInfo, 0);
      fileInfo.didFail = false;
      if (fileInfo.media) {
        await this.setAvatar(fileInfo.media.downloadUrl);
      }
    } catch (error) {
      fileInfo.didFail = true;

      const message = await firstValueFrom(
        this.translate.get('components.postInput.errors.uploadFile', {
          error: error.status || error.results.xstatus,
        })
      );

      const toast = await this.toast.create({
        message,
        position: 'top',
        duration: 3000,
      });

      toast.present();
      this.uploadingAvatar = false;
    }
  }

  async setAvatar(url: string): Promise<void> {
    try {
      if (this.type === 'company' && !this.client) {
        await this.clientService.setClientLogo({
          url,
        });
      } else if (this.type === 'company' && this.client) {
        await this.clientService.setClientLogo({
          url: url,
          id: this.client.id,
        });
      } else {
        await this.mediaService.setUserAvatar({
          url,
        });
      }

      if (this.type === 'company' && this.client?.id) {
        const updatedClient = await this.getClientById(this.client.id);
        if (updatedClient instanceof Client) {
          this.client = updatedClient;
        }
      } else if (this.type === 'company' && this.currentUser?.clientInfo.id !== undefined) {
        const updatedClient = await this.getClientById(this.currentUser?.clientInfo.id);
        if (updatedClient instanceof Client) {
          this.client = updatedClient;
        }
      }

      const user = await this.loadUser(1);
      this.currentUser = user!;
      this.userService.currentUser = user;

      // if (avatarUploadResponse) {
      //   TODO SHOW BADGE
      // }
    } catch (error) {
      this.errorService.toastError(
        'components.postInput.errors.uploadFile',
        error.status || error.results.xstatus
      );
    } finally {
      this.uploadingAvatar = false;
    }
  }

  private async getClientById(id: Client['id']): Promise<Client | undefined> {
    const body = {};
    return this.errorService.resolve(this.agencyService.getClientById(id, body), {
      errorMessage: 'components.clientTable.errors.getClients',
      errorDefault: [],
    });
  }
}
