import { Component, OnInit, ViewChild, OnDestroy, HostBinding, isDevMode } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService } from 'netsocial-posting/src/app/services/user/user.service';
import {
  PopoverController,
  ActionSheetController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { NotificationListComponent } from '../notification-list/notification-list.component';
import { NotificationService } from 'netsocial-lib/src/app/services/notification/notification.service';
import { ErrorService } from 'netsocial-posting/src/app/services/error/error.service';
import { TranslateService } from '@ngx-translate/core';
import {
  SelectListComponent,
  ListOption,
} from 'netsocial-posting/src/app/components/shared-components/select-list/select-list.component';
import { observable, computed } from 'mobx-angular';
import { GetTheAppComponent } from '../../shared-components/get-the-app/get-the-app.component';
import { NsSelectComponent } from 'netsocial-posting/src/app/components/shared-components/ns-select/ns-select.component';
import { MainHeaderService } from './main-header.service';
import { capitalize, hasSubString } from 'netsocial-lib/src/app/utils/string-utils';
import { ApiRequestService } from 'netsocial-lib/src/app/services/api-request/api-request.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { Client } from 'netsocial-lib/src/app/interfaces/client';
import { ResponsiveService } from '../../../services/responsive/responsive.service';
import { AgencyService } from '../../../services/agency/agency.service';
import { NetSocialAccount } from 'netsocial-lib/src/app/services/lookup/lookup.service';
import { StorageService } from 'netsocial-lib/src/app/services/storage/storage.service';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('contextSelect', { static: false })
  contextSelect: NsSelectComponent;

  @observable currentRouteData: {
    altTitle?: string;
    title?: string;
    companyTitle?: string;
    titleMobile?: string;
    noAccounts?: boolean;
    icon?: string;
    iconSrc?: string;
  } = {};

  @computed
  get isDesktop(): boolean {
    return this.responsiveService.isDesktop;
  }

  @HostBinding('class.company-view')
  @computed
  get isCompanyView(): boolean {
    return this.userService.currentOwnerContext?.accountType === 'company';
  }
  selectedClient: Client;

  @computed
  get client(): Client | undefined {
    return this.mainHeaderService.client;
  }

  @computed
  get clientLoading(): boolean {
    return !!this.mainHeaderService.loadClientSubscription;
  }

  @computed
  get title(): string {
    let title = this.currentRouteData.altTitle || this.currentRouteData.title;
    if (
      this.userService.currentOwnerContext?.accountType === 'company' &&
      this.currentRouteData.companyTitle
    ) {
      title = this.currentRouteData.companyTitle;
    }

    if (this.currentRouteData.titleMobile && !this.isDesktop) {
      title = this.currentRouteData.titleMobile;
    }

    return `workspace.titles.${title}`;
  }

  @computed
  get viewingAs(): string {
    return this.userService.currentOwnerContextInput.name;
  }

  @computed
  get agencyAdmin(): boolean {
    return (
      this.userService.currentOwnerContext?.ownerId === this.userService.currentUser?.clientId &&
      this.userService.currentUser?.clientInfo.isAgency === '1'
    );
  }

  @computed
  get allAccounts(): NetSocialAccount[] | undefined {
    const allAccounts = this.userService.netSocialAccountsInput?.items.filter((item) => {
      item.name !== 'Myself';
    });
    return allAccounts;
  }

  private contextOwnerSubscription: Subscription;

  constructor(
    public readonly userService: UserService,
    public readonly mainHeaderService: MainHeaderService,
    private readonly router: Router,
    private readonly popover: PopoverController,
    private readonly notificationService: NotificationService,
    private readonly errorService: ErrorService,
    private readonly translate: TranslateService,
    private readonly actionSheet: ActionSheetController,
    private readonly modal: ModalController,
    private readonly storage: StorageService,
    private readonly apiRequest: ApiRequestService,
    private readonly responsiveService: ResponsiveService,
    private readonly agencyService: AgencyService,
    private readonly platform: Platform
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe((route: ActivationEnd) => {
        if (!route.snapshot.children.length) {
          this.currentRouteData = route.snapshot.data;
        }
      });

    this.contextOwnerSubscription = this.apiRequest.getContextOwner().subscribe(() => {
      this.mainHeaderService.loadClient();
    });

    this.userService.loadTranslations();
    this.loadClientDetails();
  }

  ngOnDestroy(): void {
    this.contextOwnerSubscription.unsubscribe();
  }

  async showNotifications(event: Event): Promise<void> {
    let popoverOrModal: HTMLIonPopoverElement | HTMLIonModalElement;

    if (this.isDesktop) {
      popoverOrModal = await this.popover.create({
        component: NotificationListComponent,
        event,
        showBackdrop: false,
        cssClass: 'notification-list',
      });
    } else {
      popoverOrModal = await this.modal.create({
        component: NotificationListComponent,
        componentProps: {
          showHeader: true,
        },
      });
    }
    await popoverOrModal.present();
    return popoverOrModal.onDidDismiss().then(async () => {
      if (this.userService.hasUnreadNotifications) {
        try {
          const notificationPromises: Promise<void>[] = [];

          this.userService.notifications.forEach((notification) => {
            if (notification.readStatus === '0') {
              notificationPromises.push(
                this.notificationService.setNotificationStatus({
                  notificationId: notification.id,
                  status: 1,
                })
              );
            }
          });

          await Promise.all(notificationPromises);

          this.userService.notifications.forEach((notification) => {
            notification.readStatus = '1';
          });
        } catch (error) {
          this.errorService.toastError('errors.markRead', error.status || error.results.xstatus);
        }
      }
    });
  }

  goToProfilePage(): void {
    this.router.navigate(['workspace/settings/account-settings']);
  }

  openContextMenu(event: Event): void {
    this.contextSelect.open(event);
  }

  async loadClientDetails(): Promise<void> {
    if (this.userService.currentOwnerContextInput.ownerId > -1) {
      const selectedClient = await this.getClientById(
        this.userService.currentOwnerContextInput.ownerId
      );
      if (
        this.userService.currentOwnerContextInput.ownerId > -1 &&
        selectedClient instanceof Client
      ) {
        this.selectedClient = selectedClient;
      }
    }
  }

  async reloadClientDetails(account: NetSocialAccount): Promise<void> {
    if (account.ownerId > -1) {
      const selectedClient = await this.getClientById(account.ownerId);
      if (selectedClient instanceof Client) {
        this.selectedClient = selectedClient;
      }
    }
  }

  async openUserMenu(event: Event): Promise<void> {
    // const upgradeText = await this.translate.get('upgradeText').toPromise();
    const myProfileText = await firstValueFrom(this.translate.get('myProfileText'));
    const badgesText = await firstValueFrom(this.translate.get('badgesText'));
    const helpText = await firstValueFrom(this.translate.get('helpText'));
    const logoutText = await firstValueFrom(this.translate.get('logoutText'));
    // const preferencesText = await this.translate.get('preferencesText').toPromise();
    const getTheAppText = await firstValueFrom(this.translate.get('getTheAppText'));
    const getTheExtensionText = await firstValueFrom(this.translate.get('getTheExtensionText'));
    // const notificationSettingsText = await this.translate.get('workspace.titles.notificationSettingsTitle').toPromise();
    const changePasswordText = await firstValueFrom(
      this.translate.get('workspace.titles.changePasswordTitle')
    );
    const switchAccountText = await firstValueFrom(this.translate.get('switchAccountText'));

    // const preferencesSubOptions = [
    //   {
    //     text: notificationSettingsText,
    //     handler: () => {
    //       this.router.navigate(['workspace/settings/notification-settings']);
    //     },
    //   },
    //   {
    //     text: changePasswordText,
    //     handler: () => {
    //       this.router.navigate(['workspace/change-password']);
    //     },
    //   },
    // ];

    // if (
    //   this.userService.currentUser.roleLabel === 'Administrator' ||
    //   this.userService.currentUser.roleLabel === 'Owner'
    // ) {
    //   preferencesSubOptions.push({
    //     icon: 'checkmark-circle',
    //     iconSlot: 'end',
    //     text: 'Dist. Approval Required',
    //     handler: () => {

    //     }
    //   });
    // }

    const buttons: ListOption[] = [
      // {
      //   text: upgradeText
      // },
      {
        icon: 'person',
        text: myProfileText,
        handler: () => {
          this.router.navigate(['workspace/settings/account-settings']);
        },
      },
      {
        text: changePasswordText,
        icon: 'key',
        handler: () => {
          this.router.navigate(['workspace/change-password']);
        },
      },
      // {
      //   text: preferencesText,
      //   handler: !this.isDesktop
      //     ? () => {
      //         this.createMenu(event, preferencesSubOptions, preferencesText);
      //       }
      //     : undefined,
      //   subOptions: preferencesSubOptions,
      // },
      {
        icon: 'help',
        text: helpText,
        handler: () => {
          window.open('https://helpme.haleymarketing.com/', '_blank');
        },
      },
      {
        icon: 'swap-horizontal',
        text: switchAccountText,
        handler: () => {
          this.userService.openAccountSwitcher(this.modal);
        },
      },
      {
        icon: 'arrow-undo',
        text: logoutText,
        handler: async () => {
          await this.userService.logout();
          this.router.navigate(['auth/login']);
        },
      },
    ];

    if (this.userService.currentOwnerContext?.agency !== '1') {
      buttons.push({
        text: badgesText,
        icon: 'ribbon',
        handler: () => {
          this.router.navigate(['workspace/badges']);
        },
      });
    }

    if (
      this.userService.currentOwnerContext?.agency !== '1' &&
      this.userService.tourPercentage !== 0
    ) {
      const resetTourText = await firstValueFrom(this.translate.get('resetTourText'));
      buttons.push({
        text: resetTourText,
        icon: 'refresh',
        handler: async () => {
          this.storage.set('completedTours', []);
          this.userService.completedTours = [];
        },
      });
    }

    if (!this.platform.is('capacitor') && !this.platform.is('electron')) {
      buttons.push({
        borderTop: true,
        icon: 'add-circle',
        text: getTheAppText,
        handler: () => {
          this.openGetTheAppModal('app');
        },
      });
    }

    if (this.isDesktop) {
      const browser = this.getBrowser();
      if (browser) {
        buttons.push({
          icon: `logo-${browser}`,
          text: getTheExtensionText,
          handler: () => {
            this.openGetTheAppModal(`extension${capitalize(browser)}`);
          },
        });
      }
    }

    return this.createMenu(event, buttons);
  }

  private async getClientById(id: Client['id']): Promise<Client | undefined> {
    const body = {};
    return this.errorService.resolve(this.agencyService.getClientById(id, body), {
      errorMessage: 'components.clientTable.errors.getClients',
      errorDefault: [],
    });
  }

  private async openGetTheAppModal(type: string): Promise<void> {
    const modal = await this.modal.create({
      component: GetTheAppComponent,
      cssClass: 'get-the-app-dialog',
      componentProps: {
        type,
      },
    });

    return modal.present();
  }

  private getBrowser(): string | undefined {
    const browsers = ['edg', 'chrome', 'firefox'];
    const browser = browsers.find((b) => {
      return hasSubString(navigator.userAgent.toLowerCase(), b);
    });

    return browser === 'edg' ? 'edge' : browser;
  }

  private async createMenu(
    event: Event,
    buttons: ListOption[],
    headerText?: string
  ): Promise<void> {
    let header = this.userService.currentUser?.clientInfo.name;
    header = headerText ? headerText : header;

    if (this.isDesktop) {
      const popover = await this.popover.create({
        component: SelectListComponent,
        event,
        translucent: true,
        componentProps: {
          dark: true,
          selectList: buttons,
          showHeader: true,
          headerText: header,
          smallHeader: true,
          showBack: false,
        },
      });

      return await popover.present();
    } else {
      const actionSheet = await this.actionSheet.create({
        header,
        buttons,
      });

      await actionSheet.present();
    }
  }
}
