import { Component } from '@angular/core';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';

interface ProgressDetail {
  action: string;
  value?: number;
}

@Component({
  selector: 'update-available',
  templateUrl: './update-available.component.html',
  styleUrls: ['./update-available.component.scss'],
})
export class UpdateAvailableComponent {
  progress?: ProgressDetail;
  updateDone = false;

  constructor(private readonly deploy: Deploy) {
    this.loadUpdate();
  }

  /**
   * download and extracts the update that was found
   */
  private async loadUpdate(): Promise<void> {
    await this.deploy.sync({ updateMethod: 'auto' }, (downloadValue) => {
      this.progress = {
        action: 'Downloading Update',
        value: downloadValue,
      };
    });

    this.updateDone = true;
    this.progress = undefined;
  }
}
