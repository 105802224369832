import { Injectable, OnDestroy } from '@angular/core';
import { observable } from 'mobx-angular';
import { BreakpointInfo, watchBreakpoints } from 'netsocial-lib/src/app/utils/app-utils';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService implements OnDestroy {
  private watchBreakpointsSubscription: Subscription;

  @observable isDesktop: BreakpointInfo['isDesktop'];
  @observable breakpoint: BreakpointInfo['breakpoint'];
  @observable size: BreakpointInfo['size'];

  constructor() {
    this.watchBreakpointsSubscription = watchBreakpoints().subscribe(
      ({ isDesktop, breakpoint, size }) => {
        this.isDesktop = isDesktop;
        this.breakpoint = breakpoint;
        this.size = size;
      }
    );
  }

  ngOnDestroy() {
    this.watchBreakpointsSubscription.unsubscribe();
  }
}
