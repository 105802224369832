import { Channel } from 'netsocial-lib/src/app/interfaces/channel';
import { StateFilterGroup } from 'src/app/interfaces/filter';

export class LoadChannel {
  static readonly type = '[Channels] Load Channel';
  constructor(public payload: Channel[]) {}
}

export class AddChannels {
  static readonly type = '[Channels] Add Channel';
  constructor(public payload: Channel[]) {}
}

export class ResetChannels {
  static readonly type = '[Channels] Reset Channel';
  constructor() {
    // do nothing
  }
}

export class ApplyChannelFilter {
  static readonly type = '[Channels] Apply Channel Filter';
  constructor(public payload: StateFilterGroup[]) {}
}

export class ResetChannelFilter {
  static readonly type = '[Channels] Reset Channel Filter';
}
