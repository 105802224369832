<div class="flex ion-align-items-center ion-justify-content-center ly-col ion-margin ion-padding">
  <ion-icon src="assets/icon/netsocial.svg"></ion-icon>
  <h3>{{ 'buildVersionModal.netSocialText' | translate }}</h3>
  <p>{{ 'buildVersionModal.netSocialVersion' | translate: { version: versions.version } }}</p>

  <p class="app-version" *ngIf="appVersion">
    {{ 'buildVersionModal.appVersion' | translate: { appVersion: appVersion } }}
  </p>

  <p class="branch-name" *ngIf="isDevMode">
    {{ 'buildVersionModal.branchText' | translate: { branch: versions.branch } }}
  </p>
  <p>{{ 'buildVersionModal.revisionText' | translate: { revision: versions.revision } }}</p>
  <p>{{ 'buildVersionModal.clientId' | translate: { clientId: this.clientId } }}</p>
  <p>{{ 'buildVersionModal.userId' | translate: { userId: this.userId } }}</p>
  <ng-container *ngIf="versions.channel || versionInfo?.buildId || versions.envTarget">
    <hr class="hr-text" />

    <h6 class="ion-no-margin ion-padding-bottom">
      {{
        'buildVersionModal.appflowInfoText' | translate: { appflowInfo: versions.appflowInfoText }
      }}
    </h6>
  </ng-container>

  <p *ngIf="versions.channel">
    {{ 'appflowChannelText' | translate: { channel: versions.channel } }}
  </p>
  <p *ngIf="versionInfo?.buildId">
    {{ 'appflowBuildIdText' | translate }}: {{ versionInfo?.buildId }}
    {{
      'buildVersionModal.appflowBuildIdText' | translate: { appflowBuildId: versionInfo?.buildId }
    }}
  </p>
  <p *ngIf="versions.envTarget">
    {{ 'buildVersionModal.envTargetText' | translate: { envTarget: versions.envTarget } }}
  </p>
</div>
