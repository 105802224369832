import { Component, Input } from '@angular/core';
import { UserService } from 'netsocial-posting/src/app/services/user/user.service';
import { NotificationService } from 'netsocial-lib/src/app/services/notification/notification.service';
import { Link, NgxLinkifyjsService } from 'ngx-linkifyjs';
import { computed, observable } from 'mobx-angular';
import { Router } from '@angular/router';
import { Notification, NotificationAction } from 'netsocial-lib/src/app/interfaces/notification';
import { ErrorService } from 'netsocial-posting/src/app/services/error/error.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApprovalService } from 'netsocial-posting/src/app/services/approval/approval.service';
import { removeObject } from 'netsocial-lib/src/app/utils/array-utils';

interface LinksAndText {
  links: Link[];
  body: SafeHtml;
}

@Component({
  selector: 'notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent {
  public popover: HTMLIonPopoverElement;
  public modal: HTMLIonModalElement;

  public actionPromise: Promise<void>;

  @observable @Input() public renderLimit = 50;
  @observable @Input() public currentRenderLimit: number;

  @computed
  public get visibleNotifications(): Notification[] {
    return this.currentRenderLimit < this.userService.notifications.length
      ? this.userService.notifications?.slice(0, this.currentRenderLimit)
      : this.userService.notifications;
  }

  @computed
  public get linksAndTextByNotification(): Record<string, LinksAndText> {
    return this.userService.notifications.reduce<Record<string, LinksAndText>>(
      (hash, notification) => {
        const links = this.linkifyService.find(notification.body);
        let body = notification.body;

        if (links && links.length) {
          links.forEach((link) => {
            body = body.split(link.href).join('');
          });
        }

        body = body.split('<br>').join('');

        hash[`${notification.id}`] = {
          links,
          body: this.sanitizer.bypassSecurityTrustHtml(body),
        };
        return hash;
      },
      {}
    );
  }

  @Input() public showHeader = false;

  constructor(
    public userService: UserService,
    private notificationService: NotificationService,
    private linkifyService: NgxLinkifyjsService,
    private router: Router,
    private errorService: ErrorService,
    private sanitizer: DomSanitizer,
    private approvalService: ApprovalService
  ) {
    this.currentRenderLimit = this.renderLimit;
  }

  public async clearAll(): Promise<void> {
    try {
      await this.notificationService.deleteNotifications({
        notificationIds: this.userService.notifications.map((notification) => notification.id),
      });

      this.userService.notifications = [];
    } catch (error) {
      this.errorService.toastError(
        'components.notificationList.errors.delete',
        error.status || error.results.xstatus
      );
    }
  }

  public async performAction(
    action: NotificationAction,
    notification: Notification,
    event: Event
  ): Promise<void> {
    event.stopPropagation();
    if (notification.route.includes('/workspace/queue/pending')) {
      this.handleApproval(action, notification);
    } else if (action.label === 'review') {
      this.actionPromise = this.navigateNotification(notification);
    }
  }

  public async navigateNotification(notification: Notification): Promise<void> {
    await this.delete(notification);
    await this.router.navigateByUrl(notification.route);
    return this.close();
  }

  public async delete(notification: Notification, event?: Event): Promise<void> {
    event?.stopPropagation();
    await this.errorService.resolve(
      this.notificationService.deleteNotifications({
        notificationIds: [notification.id],
      }),
      {
        errorMessage: 'components.notificationList.errors.delete',
        successCallback: () => {
          removeObject(this.userService.notifications, notification);
        },
      }
    );
  }

  private close() {
    (this.modal || this.popover)?.dismiss();
  }

  private handleApproval(action: NotificationAction, notification: Notification) {
    const [, id] = notification.route.split('id=');

    switch (action.label) {
      case 'skip':
        this.actionPromise = this.approvalService.setDeliveryApprovalAction(
          id,
          'decline',
          () => removeObject(this.userService.notifications, notification),
          true
        );
        break;
      case 'approve':
        this.actionPromise = this.approvalService.setDeliveryApprovalAction(
          id,
          'approve',
          () => removeObject(this.userService.notifications, notification),
          true
        );
        break;
      case 'review':
        this.actionPromise = this.navigateNotification(notification);
        break;
    }
  }
}
