export const environment = {
  production: true,
  appKey: '24f66174d9f30fc7b20b19388e4d5583',
  apiUrl: 'https://api.{environment}.netsocialapp.com',
  apiVersion: 'v1.1',
  appUrl: 'https://app.{environment}.netsocialapp.com',
  perPage: 10,
  facebookAppId: 389486628496581,
  relicOrgId: 'fef00240-2419-413d-a823-e25e040a8f50',
  relicAppId: '09946a2c-2228-4fd2-a5eb-8b1d41fd2029',
};
