<ion-row class="ion-align-items-center flex-no-wrap">
  <ion-icon
    class="ion-margin-end title-icon"
    [color]="iconColor"
    [name]="icon"
    [size]="iconSize"
  >
  </ion-icon>
  <div class="ly-col">
    <ion-text [color]="titleColor" class="title-text">
      <h3 class="ion-no-margin" [style.fontSize]="titleSize + 'px'">
        {{ title | translate }}
      </h3>
    </ion-text>

    <ion-text [color]="titleColor" class="subtitle-text" *ngIf="subtitle">
      <h3 class="ion-no-margin" [style.fontSize]="subtitleSize + 'px'">
        {{ subtitle | translate }}
      </h3>
    </ion-text>
  </div>
</ion-row>
<ng-content> </ng-content>
