<ng-container *mobxAutorun>
  <ion-button
    fill="clear"
    shape="round"
    class="button-icon ion-no-margin"
    color="medium"
    (click)="modal.dismiss()"
  >
    <ion-icon slot="icon-only" name="close"> </ion-icon>
  </ion-button>
  <div class="title ly-col">
    <h2>{{ titleForType[type] | translate }}</h2>
    <h3 [innerHTML]="'components.getTheApp.titles.' + type | translate"></h3>
    <!-- <ion-text color="primary">Netsocial</ion-text> on Every Platform -->
  </div>
  <ion-row class="ion-justify-content-center ion-align-items-center">
    <ng-container *ngFor="let option of options">
      <ion-icon
        *ngIf="option.icon"
        class="app-store-icon"
        [src]="option.icon"
        [class.large]="options.length === 1"
        (click)="open(option)"
      >
      </ion-icon>
      <ion-img
        *ngIf="option.image"
        class="app-store-icon"
        [src]="option.image"
        [class.large]="options.length === 1"
        (click)="open(option)"
      >
      </ion-img>
    </ng-container>
  </ion-row>
  <ion-icon src="assets/img/onboarding-1.svg" class="background-icon"> </ion-icon>
</ng-container>
