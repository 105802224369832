import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  NetsocialAuthKeySet,
  UserService,
} from 'netsocial-posting/src/app/services/user/user.service';
import { ErrorService } from 'netsocial-posting/src/app/services/error/error.service';
import { capitalize, hasSubString, urlToObject } from 'netsocial-lib/src/app/utils/string-utils';
import { AlertController } from '@ionic/angular';
import { ApiResponse } from 'netsocial-lib/src/app/interfaces/api-response';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly errorService: ErrorService,
    private readonly alert: AlertController
  ) {}

  private sanitizeRedirect(url: string) {
    return url.split('?accessToken')[0] ?? '';
  }

  private async checkToken(state: RouterStateSnapshot, isLoggedIn: false | NetsocialAuthKeySet) {
    if (hasSubString(state.url, 'accessToken') && hasSubString(state.url, '?')) {
      this.userService.logout(true);

      if (
        !hasSubString(state.url, 'auth/validate') &&
        !hasSubString(state.url, 'auth/password-reset')
      ) {
        let queryParams = urlToObject<{ accessToken: string | undefined }>(state.url.split('?')[1]);

        if (queryParams && queryParams.accessToken) {
          await this.errorService.resolve(
            this.userService.loginWithAccessToken(decodeURIComponent(queryParams.accessToken)),
            {
              disableLogging: true,
              errorCallback: async (error: ApiResponse<''>) => {
                const header = error?.results?.status
                  ? capitalize(error.results.status)
                  : 'Access Token Error!';
                const alert = await this.alert.create({
                  header,
                  message: error?.results?.xstatus || ' ',
                  cssClass: 'access-token-error-alert',
                });

                await alert.present();

                from(alert.onDidDismiss()).subscribe(() => {
                  if (!isLoggedIn) {
                    this.userService.logout(true);
                  }
                });
              },
              successCallback: () => {
                queryParams = { ...queryParams, accessToken: undefined };
                return this.router.navigate([location.pathname], { queryParams });
              },
            }
          );
        }
      }
    }
  }

  private async agencyRedirect() {
    if (
      !this.userService.currentOwnerContext &&
      this.userService.currentUser?.clientInfo.isAgency === '1'
    ) {
      await this.userService.mutContextOwner({
        accountType: 'company',
        agency: '1',
        ownerId: this.userService.currentUser.clientInfo.id,
        name: this.userService.currentUser.clientInfo.name,
      });
    }
  }

  private async checkCompany(state: RouterStateSnapshot) {
    if (
      state.url.includes('?') &&
      state.url.includes('viewAsCompany') &&
      state.url.includes('companyName')
    ) {
      let queryParams = urlToObject<{
        viewAsCompany?: string;
        companyName?: string;
      }>(state.url.split('?')[1]);

      await this.userService.mutContextOwner({
        accountType: 'company',
        ownerId: queryParams.viewAsCompany!,
        name: queryParams.companyName!,
      });

      queryParams = {
        ...queryParams,
        viewAsCompany: undefined,
        companyName: undefined,
      };

      return this.router.navigate([location.pathname], { queryParams });
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const _hasSubString = hasSubString;
    let redirectUrl: string | null = this.sanitizeRedirect((<any>route)['_routerState']['url']);

    const isLoggedIn = await this.userService.isLoggedIn();
    await this.checkToken(state, isLoggedIn);

    if (isLoggedIn && !this.userService.currentUser) {
      try {
        await this.userService.loadUser(isLoggedIn.userId);
        await this.checkCompany(state);
        await this.agencyRedirect();
      } catch (error) {
        await this.userService.logout();
      }
    }
    let redirectHome = false;
    let redirectValidate = false;
    let redirectLogin = false;

    const redirectMap = {
      redirectHome: this.userService.homePage,
      redirectValidate: '/auth/validate',
      redirectLogin: '/auth/login',
    };
    if (isLoggedIn) {
      if (
        (_hasSubString(state.url, 'auth') &&
          !_hasSubString(state.url, 'validate') &&
          this.userService.currentUser?.emailValid !== '0') ||
        (_hasSubString(state.url, 'validate') && this.userService.currentUser?.emailValid === '1')
      ) {
        redirectHome = true;
      }

      if (
        this.userService.currentUser?.emailValid === '0' &&
        !_hasSubString(state.url, 'validate')
      ) {
        redirectValidate = true;
      }
      // state.url.split('auth').length;
    } else {
      if (!_hasSubString(state.url, 'auth')) {
        redirectLogin = true;
      }
      if (
        (_hasSubString(state.url, 'auth/validate') && !_hasSubString(state.url, 'accessToken')) ||
        (_hasSubString(state.url, 'auth/password-reset') &&
          !_hasSubString(state.url, 'accessToken'))
      ) {
        redirectLogin = true;
        redirectUrl = null;
      }
    }

    if (redirectHome || redirectValidate) {
      let redirectRoute = redirectHome ? redirectMap['redirectHome'] : '';
      redirectRoute = redirectValidate ? redirectMap['redirectValidate'] : redirectRoute;
      this.router.navigateByUrl(this.router.createUrlTree([redirectRoute]));
      return false;
    }

    if (redirectLogin) {
      this.router.navigateByUrl(
        this.router.createUrlTree([redirectMap['redirectLogin']], {
          queryParams: { redirectUrl },
        })
      );
      return false;
    }

    return true;
  }
}
