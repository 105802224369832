import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { TourStatusComponent } from '../../onboarding/tour-status/tour-status.component';
import { ErrorService } from 'netsocial-posting/src/app/services/error/error.service';
import { ClientService } from 'netsocial-lib/src/app/services/client/client.service';
import { Client } from 'netsocial-lib/src/app/interfaces/client';
import { observable } from 'mobx-angular';

@Injectable({
  providedIn: 'root',
})
export class MainHeaderService {
  ionCloseComponent = new BehaviorSubject<undefined>(undefined);
  ionAuthLayoutChange = new BehaviorSubject<boolean>(false);
  ionToggleLogoutButton = new BehaviorSubject<boolean>(false);
  ionToggleHeader = new BehaviorSubject<boolean>(false);
  ionToggleArtIndex = new BehaviorSubject<number>(0);

  @observable client?: Client;
  @observable loadClientSubscription?: Subscription;

  constructor(private errorService: ErrorService, private clientService: ClientService) {}

  getIonCloseComponent(): Observable<undefined> {
    return this.ionCloseComponent.asObservable();
  }

  getIonAuthLayoutChange(): Observable<boolean> {
    return this.ionAuthLayoutChange.asObservable();
  }

  getIonToggleLogoutButton(): Observable<boolean> {
    return this.ionToggleLogoutButton.asObservable();
  }

  getIonToggleHeader(): Observable<boolean> {
    return this.ionToggleHeader.asObservable();
  }

  getIonToggleArtIndex(): Observable<number> {
    return this.ionToggleArtIndex.asObservable();
  }

  async openTourStatus(event: Event, popoverController: PopoverController): Promise<void> {
    const popover = await popoverController.create({
      component: TourStatusComponent,
      event,
      cssClass: 'tour-status-popover',
    });
    return popover.present();
  }

  async loadClient(): Promise<void> {
    this.loadClientSubscription = from(
      this.errorService.resolve(this.clientService.getClient())
    ).subscribe((client) => {
      this.client = client;
      this.loadClientSubscription = undefined;
    });
  }
}
