import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { observable, computed } from 'mobx-angular';

@Component({
  selector: 'ns-button-item',
  templateUrl: './ns-button-item.component.html',
  styleUrls: ['./ns-button-item.component.scss'],
})
export class NsButtonItemComponent {
  @observable @Input() value: unknown;
  @observable @Input() selectedValue: unknown;
  @observable @Input() disabled: boolean;

  @computed
  get isSelected(): boolean {
    return this.value === this.selectedValue;
  }

  changeBehaviorSubject = new Subject<unknown>();
}
