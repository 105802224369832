import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MobxAngularModule } from 'mobx-angular';
import { NsHeaderComponent } from './ns-header/ns-header.component';
import { NsHelpComponent } from './ns-help/ns-help.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { GetTheAppComponent } from './get-the-app/get-the-app.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { SharedComponentsModule as PSharedCompMod } from 'netsocial-posting/src/app/components/shared-components/shared-components.module';
import { NsButtonGroupComponent } from './ns-button-group/ns-button-group.component';
import { NsButtonItemComponent } from './ns-button-group/ns-button-item/ns-button-item.component';
import { NsDateRangePickerComponent } from './ns-date-range-picker/ns-date-range-picker.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarLogoComponent } from './avatar-logo/avatar-logo.component';
import { EarnedValueInfoComponent } from './earned-value-info/earned-value-info.component';
import { DynamicInputComponent } from './dynamic-form/dynamic-input/dynamic-input.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';

const components = [
  NsHeaderComponent,
  NsHelpComponent,
  GetTheAppComponent,
  PageTitleComponent,
  NsButtonGroupComponent,
  NsButtonItemComponent,
  NsDateRangePickerComponent,
  AvatarLogoComponent,
  EarnedValueInfoComponent,
  DynamicInputComponent,
  DynamicFormComponent,
];

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    MobxAngularModule,
    TooltipModule,
    PSharedCompMod,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class SharedComponentsModule {}
