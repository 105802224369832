import { Component, Input } from '@angular/core';
import { observable, computed } from 'mobx-angular';
import { Platform } from '@ionic/angular';

interface DownloadOption {
  url?: string;
  image?: string;
  icon?: string;
}

export const APP_STORE_URL = 'https://apps.apple.com/ca/app/netsocial/id1508912539';
export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.netsocialapp.netsocial';

@Component({
  selector: 'get-the-app',
  templateUrl: './get-the-app.component.html',
  styleUrls: ['./get-the-app.component.scss'],
})
export class GetTheAppComponent {
  readonly titleForType: Record<string, string> = {
    app: 'getTheAppText',
    extensionChrome: 'getTheExtensionText',
  };
  modal: HTMLIonModalElement;
  @observable private location = 'assets/app-badges/';
  @observable @Input() type: 'app' | 'extensionChrome' | 'extensionFirefox' | 'extensionEdge' =
    'app';
  @observable private appOptions: DownloadOption[] = [
    {
      url: APP_STORE_URL,
      icon: 'app-store-badge.svg',
    },
    {
      url: PLAY_STORE_URL,
      image: 'google-play-badge.png',
    },
  ];
  @observable private desktopAppOptions: DownloadOption[] = [
    {
      url: APP_STORE_URL,
      icon: 'mac-app-store-badge.svg',
    },
  ];
  @observable private extensionChromeOptions: DownloadOption[] = [
    {
      url: 'https://chrome.google.com/webstore/detail/kfeaifgebnnibghgnbcggbbkjcapfhfd/',
      icon: 'chrome-web-store-badge.svg',
    },
  ];

  @observable private extensionFirefox: DownloadOption[] = [
    {
      url: 'https://addons.mozilla.org/en-US/firefox/addon/netsocial-sharing-extension/',
      image: 'firefox-badge.png',
    },
  ];

  @observable private extensionEdge: DownloadOption[] = [
    {
      url: 'https://microsoftedge.microsoft.com/addons/detail/pmafdkmajcfcjopocnkkffaidlomiilf',
      image: 'microsoft-badge.png',
    },
  ];

  @computed
  get options(): DownloadOption[] {
    let options: DownloadOption[] = [];
    switch (this.type) {
      case 'app':
        options = this.appOptions;
        if (this.platform.is('desktop')) {
          options = [...options, ...this.desktopAppOptions];
        }
        break;
      case 'extensionChrome':
        options = this.extensionChromeOptions;
        break;
      case 'extensionFirefox':
        options = this.extensionFirefox;
        break;
      case 'extensionEdge':
        options = this.extensionEdge;
        break;
    }

    return options.map((option) => {
      return {
        ...option,
        icon: option.icon ? `${this.location}${option.icon}` : undefined,
        image: option.image ? `${this.location}${option.image}` : undefined,
      } as DownloadOption;
    });
  }

  constructor(private platform: Platform) {}

  open(option: DownloadOption): void {
    if (option.url) {
      window.open(option.url, '_blank');
    }
  }
}
