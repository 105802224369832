import { Component, Input } from '@angular/core';
import { FormConfig } from 'src/app/interfaces/forms';

@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent {
  @Input() formConfig: FormConfig;
}
