<div class="ly-col flex ion-align-items-center ion-justify-content-center">
  <div class="ly-col ion-align-items-center ion-justify-content-center">
    <div class="pulse-container">
      <div class="pulse">
        <span></span>
      </div>
      <ion-icon src="assets/icon/netsocial.svg" class="ion-margin-bottom logo-icon"></ion-icon>
    </div>
    <span *ngIf="progress" class="ion-margin-vertical ion-padding-top">
      {{ progress.action }} {{ progress.value }}%
    </span>
    <span *ngIf="updateDone" class="ion-margin-vertical ion-padding-top"> Applying Update </span>
    <ion-spinner name="dots" *ngIf="progress || updateDone"></ion-spinner>
  </div>
</div>
