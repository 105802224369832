import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import * as Sentry from '@sentry/angular';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  constructor(private swUpdate: SwUpdate) {}

  async versionReload(): Promise<Subscription> {
    return this.swUpdate.versionUpdates.subscribe((event) => {
      if (event.type === 'VERSION_READY') {
        Sentry.addBreadcrumb({
          category: 'Reload',
          message: 'realoading window because new verion',
          level: 'info',
        });
        window.location.reload();
      }
    });
  }
}
