<div class="ly-col ion-align-items-start">
  <ion-text color="light" class="">
    Earned Value is a calculated monetary value 
    associated with the combined volume of comments, 
    clicks, likes and shares for a social campaign. 
  </ion-text>
  <br>
  <ion-text color="light" class="">
    It's a proprietary computation that provides an
    estimated value and understanding of your company's
     marketing efforts across social media. 
  </ion-text>
</div>
