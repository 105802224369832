import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MobxAngularModule } from 'mobx-angular';
import { TooltipModule } from 'ng2-tooltip-directive';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { MainHeaderComponent } from './main-header/main-header.component';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
import { SharedComponentsModule as PSharedCompMod } from 'netsocial-posting/src/app/components/shared-components/shared-components.module';
import { JoyrideModule } from 'ngx-joyride';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { PROMISE_BUTTONS_MODULE_OPTIONS } from 'netsocial-posting/src/app/utils/constants';
import { NotificationCheckPipe } from './notification-check.pipe';
import { BuildVersionsComponent } from './build-version/build-version.component';
import { UpdateAvailableComponent } from './update-available/update-available.component';

const components = [
  MainHeaderComponent,
  MainNavigationComponent,
  NotificationListComponent,
  BuildVersionsComponent,
  NotificationCheckPipe,
  UpdateAvailableComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    TooltipModule,
    MobxAngularModule,
    SharedComponentsModule,
    PSharedCompMod,
    JoyrideModule.forChild(),
    Angular2PromiseButtonModule.forRoot(PROMISE_BUTTONS_MODULE_OPTIONS),
  ],
  exports: [...components],
})
export class AppShellModule {}
