import {
  ChannelState,
  ChannelStateModel,
} from './components/channel/channel-page/channel-page.component.state';
import {
  TeamState,
  TeamsStateModel,
} from './workspace/queue/team-schedule/team-schedule.page.state';

export const appState = [ChannelState, TeamState];

export interface AppState {
  channels: ChannelStateModel;
  teams: TeamsStateModel;
}
