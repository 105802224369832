import { Pipe, PipeTransform } from '@angular/core';
import { MenuNotification } from 'netsocial-lib/src/app/interfaces/menu-notification';

@Pipe({ name: 'notificationCheck' })
export class NotificationCheckPipe implements PipeTransform {
  transform(
    menuNotification: MenuNotification,
    notificationNames: Array<keyof MenuNotification> | keyof MenuNotification = []
  ): number {
    return (typeof notificationNames === 'string' ? [notificationNames] : notificationNames).reduce(
      (sum, name) => {
        if (typeof menuNotification[name] === 'number') {
          sum += menuNotification[name];
        }

        return sum;
      },
      0
    );
  }
}
