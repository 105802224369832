import { Injectable } from '@angular/core';
import { ApiRequestService } from 'netsocial-lib/src/app/services/api-request/api-request.service';
import { Role } from '../../interfaces/role';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private apiRequest: ApiRequestService) {}

  async getRoles() {
    const response = await this.apiRequest.request('roles/get');
    const roles: Role[] = response.results.data;

    return roles;
  }
}
