import { NgModule, ErrorHandler, Injectable, isDevMode, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { RouteReuseStrategy } from '@angular/router';
import { IonicModule /* IonicRouteStrategy */ } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { RegistrationService } from './services/registration/registration.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AccountService } from 'netsocial-posting/src/app/services/account/account.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainHeaderService } from './components/app-shell/main-header/main-header.service';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SharedComponentsModule as PSharedCompMod } from 'netsocial-posting/src/app/components/shared-components/shared-components.module';
import { JoyrideModule } from 'ngx-joyride';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ChannelService } from 'netsocial-posting/src/app/services/channel/channel.service';
// import * as Sentry from '@sentry/angular';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { StorageService } from 'netsocial-lib/src/app/services/storage/storage.service';
import { Drivers } from '@ionic/storage';
import { Router } from '@angular/router';
import { PostingModule } from 'netsocial-posting/src/app/posting/posting.module';
import { AppShellModule } from './components/app-shell/app-shell.module';
import { NSSentryErrorHandler } from './sentry-error-handler';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { appState } from './app.state';

export class FileReaderA extends window.FileReader {
  constructor() {
    super();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const zoneOriginalInstance = (this as any)['__zone_symbol__originalInstance'];
    return zoneOriginalInstance || this;
  }
}

window.FileReader = FileReaderA;

export const createTranslateLoader = (http: HttpClient): MultiTranslateHttpLoader => {
  return new MultiTranslateHttpLoader(http, [
    {
      prefix: './assets/i18n/',
      suffix: '.json',
    },
    {
      prefix: './assets/i18n/netsocial-posting/',
      suffix: '.json',
    },
    {
      prefix: './assets/i18n/netsocial-lib/',
      suffix: '.json',
    },
    {
      prefix: './assets/i18n/image-library/',
      suffix: '.json',
    },
  ]);
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    JoyrideModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    PSharedCompMod,
    AppShellModule,
    PostingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    NgxsModule.forRoot(appState),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
  ],
  exports: [TranslateModule],
  providers: [
    StorageService,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: StorageService) => {
        return () => appConfigService.init();
      },
      deps: [StorageService],
      multi: true,
    },
    StatusBar,
    SplashScreen,
    RegistrationService,
    AccountService,
    MainHeaderService,
    ChannelService,
    Deploy,
    { provide: ErrorHandler, useClass: NSSentryErrorHandler },
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(trace: TraceService) {
    //
  }
}
