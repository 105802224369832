import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./authenticate/authenticate.module').then((m) => m.AuthenticatePageModule),
  },
  {
    path: 'workspace',
    loadChildren: () => import('./workspace/workspace.module').then((m) => m.WorkspacePageModule),
    canActivate: [AuthGuard],
    data: { hasNavigation: true },
  },
  { path: '**', redirectTo: '/workspace' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
