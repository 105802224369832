import { Component, Injector, isDevMode, NgZone, OnDestroy, OnInit } from '@angular/core';

import { ModalController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from 'netsocial-posting/src/app/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { safariItemFix, getBrowser } from 'netsocial-lib/src/app/utils/app-utils';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { versions } from '../environments/versions';
import { ApiRequestService } from 'netsocial-lib/src/app/services/api-request/api-request.service';
import { App } from '@capacitor/app';
import { Filesystem } from '@capacitor/filesystem';
import { DeviceShareService } from './services/device-share/device-share.service';
import { DateUtils } from 'netsocial-lib/src/app/utils/date-utils';
import { AuthGuard } from './guards/auth-guard';
import { SendIntent } from 'send-intent';
import { openPostDialog } from './utils/post-utils';
import { Post } from 'netsocial-lib/src/app/interfaces/post';
import { Subscription } from 'rxjs';
import { lastObject } from 'netsocial-lib/src/app/utils/array-utils';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import {
  APP_STORE_URL,
  PLAY_STORE_URL,
} from './components/shared-components/get-the-app/get-the-app.component';
import { Device } from '@capacitor/device';
import { StorageService } from 'netsocial-lib/src/app/services/storage/storage.service';
import { PwaService } from './services/pwa/pwa.service';
import * as Sentry from '@sentry/angular';

// 10 minutes
const UPDATE_CHECK_INTERVAL = 600000;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public loggingIn: boolean;

  private userSubscription?: Subscription;
  showUpdateAvailable = false;

  private toastRef?: HTMLIonToastElement;

  constructor(
    public readonly userService: UserService,
    private readonly platform: Platform,
    private readonly splashScreen: SplashScreen,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly statusBar: StatusBar,
    private readonly deploy: Deploy,
    private readonly zone: NgZone,
    private readonly route: ActivatedRoute,
    private readonly injector: Injector,
    private readonly modal: ModalController,
    private readonly dateUtils: DateUtils,
    private readonly deviceShare: DeviceShareService,
    private readonly toast: ToastController,
    private readonly storage: StorageService,
    private readonly pwaService: PwaService
  ) {
    this.forceSSL();
    this.initializeApp();
    this.pwaService.versionReload();

    if (getBrowser() === 'safari') {
      this.fixSafari();
    }

    if (versions.envTarget && !ApiRequestService.envTarget) {
      ApiRequestService.envTarget = versions.envTarget;
    }

    this.setDevFeatures();
  }

  async setDevFeatures(): Promise<void> {
    const info = await Device.getInfo();

    if (isDevMode() || info.isVirtual) {
      const envTargetFromStorage = await this.storage.get<string | undefined>('envTarget');
      const currentUrl = new URL(window.location.href);
      const hostSplit = currentUrl.hostname.split('.');
      const devEnv = ['release', 'release2', 'bugfix', 'stable'];

      ApiRequestService.envTarget = 'release';

      if (hostSplit[0] === 'localhost' && envTargetFromStorage) {
        ApiRequestService.envTarget = envTargetFromStorage;
      } else {
        ApiRequestService.envTarget = devEnv.includes(hostSplit[1])
          ? hostSplit[1]
          : ApiRequestService.envTarget;
      }

      console.log(`env: ` + ApiRequestService.envTarget);
    }
  }

  ngOnInit(): void {
    this.userService.loadTranslations();
    this.dateUtils.loadTranslations();
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  fixSafari(): void {
    safariItemFix;
  }

  private forceSSL(): void {
    const myLocation = window.location;
    if (!myLocation.host.match(/localhost/gi) && myLocation.protocol.match(/http:/gi)) {
      myLocation.href = myLocation.href.replace('http', 'https');
    }
  }

  initializeApp(): void {
    this.platform.ready().then(() => {
      this.translate.setDefaultLang('en-us');
      this.translate.use('en-us');
      this.dateUtils.loadTranslations();

      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // this.statusBar.overlaysWebView(true);
      // this.statusBar.backgroundColorByHexString('#ffffff');

      this.addDeepLinksListener();
      this.setupChannel();

      this.addShareListener();
    });
  }

  async setupChannel(): Promise<void> {
    const info = await Device.getInfo();

    // do not run live updates if running locally
    if (!info.isVirtual && versions.channel) {
      await this.deploy.configure({
        channel: versions.channel,
        updateMethod: 'none',
        // we don't want auto because we don't want to accidentally install a binary incompatible version
      });

      // starts checking for updates
      this.updateCheckInterval();

      setInterval(async () => {
        this.updateCheckInterval();
      }, UPDATE_CHECK_INTERVAL);
    }
  }

  private async updateCheckInterval(): Promise<void> {
    // if already downloading and applying update or alerting user of update status, do nothing
    if (this.showUpdateAvailable || this.toastRef) return;

    const update = await this.deploy.checkForUpdate();

    // if there is no update, do nothing
    if (!update.available) return;

    await this.deploy.downloadUpdate();
    await this.deploy.extractUpdate();

    if (update.snapshot) {
      const version = await this.deploy.getVersionById(update.snapshot);
      const configuration = await this.deploy.getConfiguration();

      const currentVersion = await this.deploy.getCurrentVersion();

      // if latest version is already installed, do nothing.
      if (currentVersion?.versionId === version?.versionId) return;

      const binaryMatched =
        version && version.binaryVersionCode === configuration.binaryVersionCode;

      this.toastRef = await this.toast.create({
        message: binaryMatched
          ? 'App Update Available!'
          : 'Please update your app from the store to continue receiving the latest and greatest from NetSocial',
        buttons: [
          {
            text: 'Update Now',
            handler: () => {
              if (binaryMatched) {
                this.showUpdateAvailable = true;
              } else {
                window.open(this.platform.is('ios') ? APP_STORE_URL : PLAY_STORE_URL, '_blank');
              }
            },
          },
        ],
      });

      this.toastRef.present();
      this.toastRef.onDidDismiss().then(() => {
        this.toastRef = undefined;
      });
    }
  }

  private addDeepLinksListener(): void {
    App.addListener('appUrlOpen', (data) => {
      this.zone.run(async () => {
        const slug = data.url.split('.com').pop();
        if (slug) {
          const [url, accessToken] = slug.split('?accessToken=');

          if (
            (slug.includes('/auth/accept-invite') ||
              slug.includes('/auth/login') ||
              slug.includes('/auth/validate') ||
              slug.includes('/auth/password-reset')) &&
            accessToken
          ) {
            await this.router.navigate([url], {
              queryParams: { accessToken: accessToken },
            });

            this.forceRunAuthGuard();
          } else {
            this.router.navigateByUrl(slug);
          }
        }
      });
    });
  }
  /**
   * Adds listener used to watch for external sharing events to Netsocial
   */
  addShareListener(): void {
    try {
      this.deviceShare.init();
    } catch (error) {
      // catch any errors caused by pushing to appflow without updating the app
    }

    SendIntent.checkSendIntentReceived().then((result) => {
      const body = result.title ? AppComponent.getResultTitle(result.title) : '';

      const post = new Post(this.dateUtils);
      let mediaBlob: Blob | undefined;

      if (result.url) {
        const resultUrl = decodeURIComponent(result.url);

        Filesystem.readFile({ path: resultUrl })
          .then(async (content) => {
            try {
              const response = await fetch(`data:image/jpeg;base64,${content.data}`);
              mediaBlob = await response.blob();
            } catch (error) {
              //
            }

            post.content = [
              {
                service: '',
                body,
                media: [],
              },
            ];
            if (this.userService.currentUser) {
              const modal = await openPostDialog(this.modal, 'post-dialog', {
                post,
                mediaBlob,
              });

              modal.present();
            } else {
              this.userSubscription = this.userService.userSubject
                .asObservable()
                .subscribe(async (user) => {
                  if (user) {
                    const modal = await openPostDialog(this.modal, 'post-dialog', {
                      post,
                      mediaBlob,
                    });

                    modal.present();
                  }
                });
            }
          })
          .catch();
      }
    });
  }

  private forceRunAuthGuard(): void {
    if (this.route.root.children.length) {
      // gets current route
      const curr_route = this.route.root.children['0'];
      // makes custom RouterStateSnapshot object
      const routerStateSnapshot: RouterStateSnapshot = Object.assign({}, curr_route.snapshot, {
        url: this.router.url,
      });

      const authGuard = this.injector.get(AuthGuard);

      // runs canActivate
      authGuard.canActivate(curr_route.snapshot, routerStateSnapshot);
    }
  }

  private static getResultTitle(title: string) {
    const titlePieces = title.toLowerCase().split('.');
    const possibleImageExtensions = ['gif', 'png', 'jpeg', 'jpg'];
    const possibleVideoExtensions = ['mp4', 'mov'];

    return [...possibleImageExtensions, ...possibleVideoExtensions].reduce(
      (isImageOrVideo, extension) => {
        if (lastObject(titlePieces) === extension) {
          isImageOrVideo = true;
        }

        return isImageOrVideo;
      },
      false
    )
      ? ''
      : title;
  }
}
