import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import * as Sentry from '@sentry/angular';
import { init, instrumentAngularRouting, BrowserTracing } from '@sentry/angular-ivy';
import { getApiUrl, getCurrentEnvironmentKeyword } from 'netsocial-lib/src/app/utils/app-utils';
import { Integrations } from '@sentry/tracing';
import 'send-intent';
import { versions } from './environments/versions';
import { loadRelicx } from './app/utils/app-utils';

if (environment.production) {
  /* issue referenced for beforeSend fnt: https://github.com/getsentry/sentry-javascript/issues/2292 */
  enableProdMode();
  init({
    release: `netsocial#${versions.branch}-${versions.revision}`,
    dsn: 'https://4f25b3a8ff48402ea405b4c7747303d5@sentry.io/5172226',
    environment: getCurrentEnvironmentKeyword(),
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new BrowserTracing({
        tracingOrigins: ['localhost', getApiUrl()],
        routingInstrumentation: instrumentAngularRouting,
      }),
    ],
    tracesSampleRate: 0.5,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'Failed to fetch'],
    beforeSend: (event, hint) => {
      let isNonErrorException = false;
      if (
        typeof event === 'object' &&
        typeof event !== undefined &&
        event.exception !== undefined &&
        event.exception?.values !== undefined &&
        event.exception?.values[0] !== undefined &&
        event.exception?.values[0]?.value
      ) {
        isNonErrorException = event?.exception?.values[0]?.value.startsWith(
          'Non-Error exception captured'
        )
          ? true
          : false;
      } else if (
        hint !== undefined &&
        hint !== null &&
        hint.originalException !== undefined &&
        hint.originalException !== null &&
        (<Error>hint.originalException)['message'] !== undefined
      ) {
        isNonErrorException = (<Error>hint.originalException)['message'].startsWith(
          'Non-Error exception captured'
        )
          ? true
          : false;
      }
      if (isNonErrorException) {
        return null;
      }
      return event;
    },
  });
  loadRelicx();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
