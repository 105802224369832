import { environment } from '../../environments/environment';

export const loadRelicx = (): void => {
  const rjs = document.getElementsByTagName('script')[0];
  if (document.getElementById('relicx-jssdk')) {
    return;
  }
  const js = document.createElement('script');
  js.id = 'relicx-jssdk';
  js.src = 'https://sdk.relicx.ai/relicx-sdk.min.js';
  js.async = true;
  js.addEventListener('load', () => {
    relicxSDK.init({ orgID: environment.relicOrgId, appID: environment.relicAppId });
  });

  rjs.parentNode?.insertBefore(js, rjs);
};

export const getOpacity = (opacity: number): string => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return _opacity.toString(16).toUpperCase();
};
