import { Injectable, OnDestroy } from '@angular/core';
import { Filesystem } from '@capacitor/filesystem';
import { ModalController, Platform } from '@ionic/angular';
import { Post } from 'netsocial-lib/src/app/interfaces/post';
import { lastObject } from 'netsocial-lib/src/app/utils/array-utils';
import { DateUtils } from 'netsocial-lib/src/app/utils/date-utils';
import { UserService } from 'netsocial-posting/src/app/services/user/user.service';
import { Subscription } from 'rxjs';
import { SendIntent } from 'send-intent';
import { openPostDialog } from '../../utils/post-utils';

@Injectable({
  providedIn: 'root',
})
export class DeviceShareService implements OnDestroy {
  private handledIntent = false;
  private sharingModalRef?: HTMLIonModalElement;
  private userSubscription?: Subscription;

  constructor(
    private readonly userService: UserService,
    private readonly modal: ModalController,
    private readonly dateUtils: DateUtils,
    private readonly platform: Platform
  ) {}

  /**
   * initialize sharing capabilities
   */
  init(): void {
    this.intentHandler();

    window.addEventListener('sendIntentReceived', () => {
      if (!this.handledIntent) {
        this.handledIntent = true;
        this.intentHandler();
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  /**
   * Actual handler for intent in IOS and Android
   */
  private intentHandler() {
    SendIntent.checkSendIntentReceived().then(async (result) => {
      const isEmpty = !result.description && !result.title && !result.type && !result.url;
      // can be empty on start up for IOS
      if (isEmpty) {
        return;
      }

      this.handledIntent = true;

      // description present in IOS, use instead of title if present
      let body = result.title
        ? result.description ??
          (!DeviceShareService.isImageOrVideo(result.title) ? result.title : '')
        : '';

      const post = new Post(this.dateUtils);
      let mediaBlob: Blob | undefined;

      const iosNoMedia = this.platform.is('ios') && !DeviceShareService.isImageOrVideo(result.url);
      const androidNoMedia = !DeviceShareService.isImageOrVideo(result.title);

      // IOS: url in ios will resolve to not be an image if sharing a link
      // will always resolve to an image on android
      // include url if post is lacking media
      // ANDROID: url in android resolves to a screenshot if sharing a link
      // remove media for android to just use link preview
      if (iosNoMedia || androidNoMedia) {
        body += iosNoMedia ? `${(body ? ' ' : '') + result.url}` : '';
        result.url = undefined;
      }

      if (result.url) {
        const resultUrl = decodeURIComponent(result.url);

        try {
          const content = await Filesystem.readFile({ path: resultUrl });
          const response = await fetch(`data:image/jpeg;base64,${content.data}`);
          mediaBlob = await response.blob();
        } catch (error) {
          // error
        }
      }

      post.content = [
        {
          service: '',
          body: decodeURIComponent(body),
          media: [],
        },
      ];

      if (this.userService.currentUser) {
        const modal = await openPostDialog(this.modal, 'post-dialog', {
          post,
          mediaBlob,
        });

        this.sharingModalRef?.dismiss();
        this.sharingModalRef = modal;

        await modal.present();
        this.handledIntent = false;
      } else {
        this.userSubscription = this.userService.userSubject
          .asObservable()
          .subscribe(async (user) => {
            if (user) {
              const modal = await openPostDialog(this.modal, 'post-dialog', {
                post,
                mediaBlob,
              });

              this.sharingModalRef?.dismiss();
              this.sharingModalRef = modal;

              await modal.present();
              this.handledIntent = false;
            }
          });
      }
    });
  }

  private static isImageOrVideo(title = ''): boolean {
    const titlePieces = title.toLowerCase().split('.');
    const possibleImageExtensions = ['gif', 'png', 'jpeg', 'jpg'];
    const possibleVideoExtensions = ['mp4', 'mov'];

    return [...possibleImageExtensions, ...possibleVideoExtensions].reduce<boolean>(
      (isImageOrVideo, extension) => {
        if (lastObject(titlePieces) === extension) {
          isImageOrVideo = true;
        }

        return isImageOrVideo;
      },
      false
    );
  }
}
