<div class="avatar-container" (click)="selectFile()">
  <ng-container *ngIf="!uploadingAvatar">
    <entity-avatar *ngIf="currentUser && type !== 'company'" [entity]="currentUser">
    </entity-avatar>
    <ng-container *ngIf="currentUser && !client && type === 'company'">
      <ion-avatar [ngClass]="classNames" class="md-click display-flex">
        <ng-container *ngIf="!loading">
          <img
            [alt]="currentUser?.fullName"
            [src]="currentUser.clientInfo.avatar"
            *ngIf="currentUser"
          />
        </ng-container>
        <ion-skeleton-text *ngIf="loading" animated> </ion-skeleton-text>
      </ion-avatar>
    </ng-container>
    <ng-container *ngIf="currentUser && client && type === 'company'">
      <ion-avatar [ngClass]="classNames" class="md-click display-flex">
        <ng-container *ngIf="!loading">
          <img [alt]="client.name" [src]="client.avatar" *ngIf="client" />
        </ng-container>
        <ion-skeleton-text *ngIf="loading" animated> </ion-skeleton-text>
      </ion-avatar>
    </ng-container>
    <ion-icon name="pencil-sharp" class="edit"> </ion-icon>
  </ng-container>

  <ng-container *ngIf="uploadingAvatar || loadingUser">
    <ion-avatar slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-avatar>
  </ng-container>
</div>
