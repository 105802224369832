import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'ns-help',
  templateUrl: './ns-help.component.html',
  styleUrls: ['./ns-help.component.scss'],
})
export class NsHelpComponent {
  @Input() public label: string;
  @Input() public message: string;

  constructor(private translate: TranslateService, private alert: AlertController) {
    this.setDefaults();
  }

  async setDefaults() {
    this.label = await firstValueFrom(this.translate.get('components.nsHelp.helpLabel'));
  }

  async helpAlert() {
    const alert = await this.alert.create({
      header: this.label,
      message: this.message,
    });

    await alert.present();
  }
}
