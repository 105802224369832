<ion-split-pane contentId="main-content" *mobxAutorun>
  <ion-menu
    [class.md-collapsed]="collapsed"
    type="overlay"
    side="start"
    contentId="main-content"
    class="md-main-menu"
    menuId="main-nav"
    [ngClass]="{ 'expand-menu': currentRouteHasChildren }"
  >
    <div [class.md-show-component]="showComponent" class="inner-menu">
      <ion-header>
        <ion-button
          (click)="showBuildVersions()"
          fill="clear"
          shape="round"
          class="button-icon md-versions-button"
          color="light"
        >
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>
        <ion-toolbar>
          <ion-icon src="assets/netsocial_logo_stacked.svg"></ion-icon>
        </ion-toolbar>
      </ion-header>
      <ion-content [class.show-children]="currentRouteHasChildren">
        <div class="menu-container">
          <ion-list class="ion-no-padding primary-menu">
            <ng-container *ngFor="let routeItem of routes; trackBy: trackElement">
              <div *ngIf="!routeItem.hide && routeItem.canActivate">
                <div
                  class="md-route-group"
                  [class.md-selected]="routeItem.selected"
                  [attr.disabled]="routeItem.disabled"
                >
                  <ion-item
                    [class.md-selected]="routeItem.selected"
                    [disabled]="routeItem.disabled"
                    (click)="navigateTo(routeItem.path)"
                    button="true"
                    detail="false"
                    [tooltip]="'workspace.titles.' + routeItem.title | translate"
                    [display]="currentRouteHasChildren"
                    [hide-delay]="25"
                    [options]="{ placement: 'right' }"
                    [joyrideStep]="'nav:' + routeItem.title"
                    [title]="
                      'components.mainNavigation.tour.' + routeItem.title + '.title' | translate
                    "
                    [text]="
                      'components.mainNavigation.tour.' + routeItem.title + '.body' | translate
                    "
                    (next?)="navigateTo(routeItem.path)"
                  >
                    <ion-icon
                      [name]="routeItem.icon"
                      [src]="routeItem.iconSrc"
                      [class.has-notifications]="
                        menuNotification
                          ? (menuNotification | notificationCheck: $any(routeItem.notificationName))
                          : false
                      "
                      [attr.data-content]="
                        menuNotification
                          ? (menuNotification | notificationCheck: $any(routeItem.notificationName))
                          : ''
                      "
                      slot="start"
                      style="pointer-events: none"
                    >
                    </ion-icon>
                    <ion-label>
                      {{
                        'workspace.titles.' +
                          (!isDesktop && routeItem.mobileTitle
                            ? routeItem.mobileTitle
                            : routeItem.title) | translate
                      }}
                    </ion-label>
                  </ion-item>
                  <ion-button
                    (click)="mutShowComponent(routeItem)"
                    *ngIf="routeItem.showComponent"
                    color="light"
                    fill="clear"
                    slot="end"
                    shape="round"
                    class="button-icon"
                  >
                    {{ routeItem.showComponent.label | translate }}
                    <ion-icon slot="icon-only" [name]="routeItem.showComponent.icon"> </ion-icon>
                  </ion-button>
                </div>
              </div>
            </ng-container>
            <!-- <ion-item
              (click)="collapse()" 
              detail="false"
              button="true" 
              class="md-non-selectable"
            >
              <ion-icon 
                [name]="collapsed ? 'caret-forward-circle' : 'caret-back-circle'" 
                slot="start"
              >
              </ion-icon>
              <ion-label>{{'workspace.menu.collapseLabel' | translate }}</ion-label>
            </ion-item> -->
          </ion-list>
          <ion-list class="ion-no-padding sub-menu" *ngIf="currentRouteHasChildren">
            <ng-container
              *ngFor="let childRoute of currentRouteData?.routes; trackBy: trackElement"
            >
              <div
                class="md-route-group md-child"
                *ngIf="!childRoute.hide && childRoute.canActivate"
                [class.md-selected]="childRoute.selected"
                [attr.disabled]="childRoute.disabled"
              >
                <ion-item
                  detail="false"
                  class="md-child"
                  button="true"
                  [disabled]="childRoute.disabled"
                  [class.md-selected]="childRoute.selected"
                  (click)="
                    childRoute.component ? openComponent(childRoute) : navigateTo(childRoute.path)
                  "
                  [joyrideStep]="'nav:' + childRoute.title"
                  [title]="
                    'components.mainNavigation.tour.' + childRoute.title + '.title' | translate
                  "
                  [text]="
                    'components.mainNavigation.tour.' + childRoute.title + '.body' | translate
                  "
                  (next?)="navigateTo(childRoute.path)"
                >
                  <ion-icon
                    [name]="childRoute.icon"
                    [src]="childRoute.iconSrc"
                    [class.has-notifications]="
                      menuNotification
                        ? (menuNotification
                            | notificationCheck: $any(childRoute.notificationName)) > 0
                        : false
                    "
                    slot="start"
                    size="small"
                    style="pointer-events: none"
                  >
                  </ion-icon>
                  <ion-label>
                    {{
                      'workspace.titles.' +
                        (!isDesktop && childRoute.mobileTitle
                          ? childRoute.mobileTitle
                          : childRoute.title) | translate
                    }}
                  </ion-label>
                </ion-item>
                <ion-button
                  shape="round"
                  class="button-icon"
                  (click)="mutShowComponent(childRoute)"
                  *ngIf="childRoute.showComponent"
                  color="light"
                  fill="clear"
                  slot="end"
                >
                  {{ childRoute.showComponent.label | translate }}
                  <ion-icon slot="icon-only" [name]="childRoute.showComponent.icon"> </ion-icon>
                </ion-button>
              </div>
            </ng-container>
          </ion-list>
        </div>
      </ion-content>
      <ion-button
        [class.button-icon]="collapsed"
        shape="round"
        fill="outline"
        color="light"
        class="ion-margin md-more"
        *ngIf="planId === '1'"
        [disabled]="true"
      >
        <ng-container *ngIf="!collapsed">
          {{ 'upgradeTextAlt' | translate }}
        </ng-container>
        <ng-container *ngIf="collapsed">
          <ion-icon name="business"></ion-icon>
        </ng-container>
      </ion-button>
    </div>
    <!-- <ion-grid 
      [class.md-show-component]="showComponent" 
      class="md-component-container ion-no-margin ion-no-padding"
    >
      <dynamic-html [content]="showComponent && showComponent.component"></dynamic-html>
    </ion-grid> -->
    <div
      *ngIf="userService.tourPercentage !== 1 && isDesktop"
      class="getting-started"
      [style.display]="userService.tourBehaviorSubject.value ? 'none' : 'initial'"
    >
      <ion-button
        *ngIf="this.contextOwner?.agency !== '1'"
        #gettingStartedButton
        class="getting-started-button"
        (click)="openTourStatus($event)"
      >
        <ion-icon name="checkmark-circle-outline" slot="start"> </ion-icon>
        {{ 'getStartedText' | translate }}
      </ion-button>
      <ion-badge color="light">
        {{ userService.remainingTours.length }}
      </ion-badge>
    </div>
  </ion-menu>
  <ion-col class="main-content ion-no-padding" id="main-content">
    <ng-content></ng-content>
    <ion-router-outlet class="md-route-body"></ion-router-outlet>
  </ion-col>
</ion-split-pane>
