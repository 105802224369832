import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Channel } from 'netsocial-lib/src/app/interfaces/channel';
import { StateFilterGroup } from 'src/app/interfaces/filter';
import {
  LoadChannel,
  ApplyChannelFilter,
  ResetChannelFilter,
} from './channel-page.component.actions';

export interface ChannelStateModel {
  list: Channel[];
  filter: StateFilterGroup[];
}

@State<ChannelStateModel>({
  name: 'channels',
  defaults: { list: [], filter: [] },
})
@Injectable()
export class ChannelState {
  @Action(LoadChannel)
  loadChannel({ patchState }: StateContext<ChannelStateModel>, { payload }: LoadChannel) {
    patchState({
      list: payload,
    });
  }

  @Action(ApplyChannelFilter)
  applyChannelFilter(
    { patchState, getState }: StateContext<ChannelStateModel>,
    { payload }: ApplyChannelFilter
  ) {
    patchState({ filter: payload });
  }

  @Action(ResetChannelFilter)
  resetChannelFilter({ patchState, getState }: StateContext<ChannelStateModel>) {
    patchState({ filter: [] });
  }
}
