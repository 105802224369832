import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import { versions } from '../environments/versions';
import * as Sentry from '@sentry/angular';

export const ERRORS_TO_IGNORE = [
  /No processing needed/, // Silent error from send intent plugin
];

export const ERRORS_TO_RELOAD = [
  /Loading chunk [\d]+ failed/,
  /Cannot read property 'isProxied' of undefined/, // caching related issue
  /Failed to fetch/, // caching related issue
  // https://github.com/ionic-team/stencil/issues/2774
];

@Injectable()
export class NSSentryErrorHandler implements ErrorHandler {
  private errorTimeOut: ReturnType<typeof setTimeout>;

  handleError(error: Error & { originalError?: Error }, devMode: boolean = isDevMode()): void {
    if (devMode) {
      console.error(error);
    } else {
      let ignore = false;
      let reload = false;

      ERRORS_TO_IGNORE.forEach((err) => {
        if (err.test(error.message)) ignore = true;
      });

      ERRORS_TO_RELOAD.forEach((err) => {
        if (err.test(error.message)) reload = true;
      });

      if (reload) {
        window.location.reload();
      } else if (!ignore) {
        const extra = Object.keys(versions).reduce<Record<string, string>>(
          (hash, key: keyof typeof versions) => {
            if (versions[key]) {
              hash[key] = <string>versions[key];
            }
            return hash;
          },
          {}
        );

        if (typeof relicxSDK !== 'undefined') {
          try {
            extra['Session URL'] = relicxSDK.getSessionUrl();
          } catch (error) {
            console.log(error);
          }
        }

        const eventId = Sentry.captureException(error.originalError || error, {
          extra,
        });

        if (this.errorTimeOut) {
          clearTimeout(this.errorTimeOut);
        }

        this.errorTimeOut = setTimeout(() => {
          Sentry.showReportDialog({ eventId });
        }, 250);
      }
    }
  }
}
