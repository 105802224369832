<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-icon
      [class.md-auto-margin]="enableCenterLayout"
      (click)="router.navigate([logoLink])"
      class="md-logo"
      src="assets/netsocial_logo.svg"
    >
    </ion-icon>
    <ion-buttons slot="end" *ngIf="enableLogoutButton">
      <ion-button
        fill="solid"
        color="primary"
        class="ion-margin-end"
        (click)="logout()"
      >
        {{ 'logoutText' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
