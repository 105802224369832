import { Team } from 'netsocial-lib/src/app/interfaces/team';
export class LoadTeams {
  static readonly type = '[Team Schedule] Load Teams';
  constructor(public payload: Team[]) {}
}

export class SelectItem {
  static readonly type = '[Selection] Select Item';
  constructor(
    public payload: (number | string)[],
    public action: 'select' | 'unselect' = 'select'
  ) {}
}

export class UnselectItem {
  static readonly type = '[Selection] Unselect Item';
  constructor(
    public payload: (number | string)[],
    public action: 'select' | 'unselect' = 'unselect'
  ) {}
}
