<ion-item *mobxAutorun="{ detach: true }">
  <ion-button fill="clear" class="button-icon" color="medium" (click)="change({ action: 'prev' })">
    <ion-icon name="caret-back-sharp" slot="icon-only"></ion-icon>
  </ion-button>
  <ion-button class="ly-row flex" fill="clear" color="medium" (click)="openCalendar()">
    <span [innerHTML]="formattedValue" class="ly-row ion-align-items-center"> </span>
  </ion-button>
  <ion-button fill="clear" class="button-icon" color="medium" (click)="change({ action: 'next' })">
    <ion-icon name="caret-forward-sharp" slot="icon-only"></ion-icon>
  </ion-button>
</ion-item>

<input
  class="owl-date-time-input"
  [owlDateTime]="dtEdit"
  [owlDateTimeTrigger]="dtEdit"
  [selectMode]="selectMode"
  [(ngModel)]="intervalValue"
/>
<owl-date-time
  #dtEdit
  (monthSelected)="chooseMonth()"
  (afterPickerOpen)="addListeners()"
  [hour12Timer]="false"
  [startAt]="value"
  [panelClass]="'range-' + interval"
  [startView]="$any(startView)"
  pickerType="calendar"
  pickerMode="dialog"
>
</owl-date-time>
