import {
  Component,
  EventEmitter,
  Output,
  AfterViewInit,
  QueryList,
  ContentChildren,
  OnDestroy,
  Input,
  OnChanges,
} from '@angular/core';
import { NsButtonItemComponent } from './ns-button-item/ns-button-item.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ns-button-group',
  templateUrl: './ns-button-group.component.html',
  styleUrls: ['./ns-button-group.component.scss'],
})
export class NsButtonGroupComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Output() ionChange = new EventEmitter();
  @ContentChildren(NsButtonItemComponent)
  buttons: QueryList<NsButtonItemComponent>;

  @Input() value: unknown;
  @Input() disabled: boolean;

  private buttonSubscriptions: Subscription[] = [];

  ngAfterViewInit(): void {
    this.setSelectedValue();
    this.buttons.map((button) => {
      button.disabled = this.disabled;
      this.buttonSubscriptions.push(
        button.changeBehaviorSubject.asObservable().subscribe((change) => {
          this.ionChange.emit(change);
        })
      );
    });
  }

  ngOnChanges(): void {
    this.setSelectedValue();
  }

  ngOnDestroy(): void {
    this.buttonSubscriptions?.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  private setSelectedValue() {
    this.buttons?.forEach((button) => {
      button.selectedValue = this.value;
    });
  }
}
