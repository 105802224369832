import { ModalController } from '@ionic/angular';
import { PostDialogComponent as component } from 'netsocial-posting/src/app/posting/post-dialog/post-dialog.component';
import { Plugins, Capacitor } from '@capacitor/core';
import { from } from 'rxjs';

const { Keyboard } = Plugins;

/**
 * opens a posting dialog
 *
 * @param controller - modal controller reference
 * @param cssClass - css class for modal
 * @param componentProps - component properties for modal
 * @returns `HTMLIonModalElement` reference
 */
export const openPostDialog = async (
  controller: ModalController,
  cssClass: string,
  componentProps: Record<string, unknown>
): Promise<HTMLIonModalElement> => {
  const keyboardPluginAvailable = Capacitor.isPluginAvailable('Keyboard');

  if (keyboardPluginAvailable) {
    await Keyboard.setAccessoryBarVisible({ isVisible: true });
  }

  const modal = await controller.create({
    component,
    cssClass,
    componentProps,
  });

  const subscription = from(modal.onDidDismiss()).subscribe(async () => {
    if (keyboardPluginAvailable) {
      await Keyboard.setAccessoryBarVisible({ isVisible: false });
    }
    subscription.unsubscribe();
  });

  return modal;
};
