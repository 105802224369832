import { Component, Input } from '@angular/core';

@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() icon: string;
  @Input() iconColor = 'medium';
  @Input() iconSize: string;
  @Input() titleColor = 'dark';
  @Input() titleSize = 22;
  @Input() subtitleSize = 15;
}
