<ng-container *mobxAutorun="{ detach: true }">
  <div class="ly-col" [style.opacity]="showSuccessAnimation ? 0 : 1">
    <div class="ly-col">
      <ion-row class="ion-justify-content-between ion-align-items-center">
        <b>{{ 'components.tourStatus.title' | translate }}</b>
        <ion-button
          (click)="popover.dismiss()"
          fill="clear"
          color="dark"
          class="button-icon"
          size="small"
        >
          <ion-icon slot="icon-only" name="close"> </ion-icon>
        </ion-button>
      </ion-row>
      <p class="ion-no-margin">
        {{ 'workspace.dashboard.gettingStarted.subTitle' | translate }}
      </p>
      <ion-row class="ion-padding-vertical flex-no-wrap ion-align-items-center">
        <small class="ion-margin-end"
          ><b>{{ (userService.tourPercentage * 100).toFixed() }}%</b></small
        >
        <ion-progress-bar
          [value]="userService.tourPercentage"
        ></ion-progress-bar>
      </ion-row>
    </div>
    <ion-list>
      <ion-item
        *ngFor="let tour of userService.currentTours"
        [detail]="false"
        [button]="!userService.completedToursHash[tour]"
        (click)="
          !userService.completedToursHash[tour] ? startTour(tour) : undefined
        "
        class="tour-step"
      >
        <ion-icon
          [name]="
            userService.completedToursHash[tour]
              ? 'checkmark-circle'
              : 'ellipse-outline'
          "
          [color]="userService.completedToursHash[tour] ? 'success' : 'dark'"
          slot="start"
        >
        </ion-icon>
        <ion-label>
          {{ 'components.tourStatus.labels.' + tour | translate }}
        </ion-label>
        <ion-icon
          *ngIf="!userService.completedToursHash[tour]"
          name="chevron-forward"
          slot="end"
        >
        </ion-icon>
      </ion-item>
    </ion-list>
    <ion-row class="ion-justify-content-end cancel-area">
      <ion-button
        fill="clear"
        color="medium"
        size="small"
        class="ion-margin-end"
        (click)="cancelTour()"
      >
        {{ 'components.tourStatus.buttons.cancelTour' | translate }}
      </ion-button>
    </ion-row>
  </div>
  <div *ngIf="showSuccessAnimation" class="ly-col success-animation-container">
    <success-animation> </success-animation>
    <ion-text class="ion-margin-top">
      <h5 class="ion-text-center ion-no-margin">
        {{ 'components.tourStatus.messages.moduleCompleted' | translate }}
      </h5>
    </ion-text>
  </div>
</ng-container>
