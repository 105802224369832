import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { InputConfig } from 'src/app/interfaces/forms';

@Component({
  selector: 'dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.scss'],
})
export class DynamicInputComponent implements OnInit {
  @Input() inputConfig: InputConfig;

  formControl: AbstractControl | null;

  ngOnInit() {
    this.setFormControl();
  }

  setFormControl = () => {
    const isFormControlAdded = !!this.inputConfig.formGroup.get(this.inputConfig.formControlName);

    if (!isFormControlAdded) {
      const defaultValueConfig =
        this.inputConfig.defaultValue !== undefined ? this.inputConfig.defaultValue : '';

      const validatorsConfig =
        this.inputConfig.validators || this.inputConfig.validators === undefined
          ? this.inputConfig.validators
          : null;

      this.inputConfig.formGroup.addControl(
        this.inputConfig.formControlName,
        new UntypedFormControl(defaultValueConfig, validatorsConfig),
        { emitEvent: false }
      );
    }

    this.formControl = this.inputConfig.formGroup.get(this.inputConfig.formControlName);
  };

  // inputConfig.type = 'toggle'
  toggleValue = () => {
    if (!this.formControl?.disabled) this.formControl?.setValue(!this.formControl.value);
  };
}
